import { InfoTooltip } from "@/common/components/info-tooltip/InfoTooltip";
import { TextfieldWithActions } from "@/common/components/textfield-with-actions/TextfieldWithActions";
import { InfoIconContainer } from "@/contractor/pages/home/projects/components/project-form-with-stepper/ProjectFormWithStepper.styles";
import { Buyout } from "@/generated/graphql";
import { FormControl } from "@mui/material";
import { useMemo, useState } from "react";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";

const Container = tw.div`flex flex-col`;
const Content = tw.div`flex items-center mt-2 mb-4`;
const TextfieldWithActionsStyled = tw(TextfieldWithActions)`mr-3 w-96`;

export const BuyoutDescription = ({
  buyout,
  saveDescription,
  loading,
  batchSave,
}: {
  buyout: Pick<Buyout, "id" | "version" | "description"> | undefined | null;
  saveDescription: (value: string) => void;
  loading?: boolean | undefined;
  batchSave?: boolean;
}) => {
  const intl = useIntl();

  const initialDescription = useMemo(
    () => buyout?.description || "",
    [buyout?.description],
  );

  const [editedDescription, setEditedDescription] =
    useState(initialDescription);
  const labelOptional = useMemo(() => {
    return `${intl.$t({ id: "BUYOUT_DESCRIPTION" })} ${intl.$t({
      id: "OPTIONAL_WITH_BRACKETS",
    })}`;
  }, [intl]);

  const tooltipLabel = useMemo(() => {
    return intl.$t({
      id: "BUYOUT_DESCRIPTION_TOOLTIP",
    });
  }, [intl]);

  const save = () => {
    saveDescription(editedDescription);
  };

  const reset = () => {
    setEditedDescription(initialDescription);
  };

  return (
    <Container>
      <Content>
        <FormControl>
          {batchSave ? (
            <TextfieldWithActionsStyled
              label={labelOptional}
              value={editedDescription}
              onChange={(e) => setEditedDescription(e.target.value)}
              onBlur={save}
            />
          ) : (
            <TextfieldWithActionsStyled
              label={labelOptional}
              value={editedDescription}
              onChange={(e) => setEditedDescription(e.target.value)}
              onClickSave={save}
              onClickClose={reset}
              showActions={editedDescription !== initialDescription}
              loading={loading && editedDescription !== initialDescription}
            />
          )}
        </FormControl>
        <InfoIconContainer>
          <InfoTooltip message={tooltipLabel} id="description-info" />
        </InfoIconContainer>
      </Content>
    </Container>
  );
};
