import { ConnectionStatusAdornment } from "@/common/components/connection-status-adornment/ConnectionStatusAdornment";
import { ExternalPOsQueryInputType } from "@/common/components/import-external-po/types/ExternalPOsQueryInputType";
import { OrderTypePickerControlled } from "@/common/components/order-type-picker/OrderTypePickerControlled";
import { DatePickerControlled } from "@/common/components/picker/components/DatePickerControlled";
import { PoNumberingSettingsCheck } from "@/common/components/po-numbering-settings-check/PoNumberingSettingsCheck";
import { getProjectSelectorLabel } from "@/common/components/projects-filter-selector/getProjectSelectorLabel";
import { SelectControlled } from "@/common/components/select/components/single/SelectControlled";
import { TextFieldControlled } from "@/common/components/textfield-controlled/TextFieldControlled";
import {
  VendorPickerCustomRender,
  vendorLabelFormatter,
} from "@/common/components/vendor-picker/VendorPickerCustomRender";
import { useVendors } from "@/common/components/vendors/hooks/useVendors";
import { MAX_VENDOR_NUMBER } from "@/common/const";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { useWarehouses } from "@/contractor/pages/admin/warehouse/providers/WarehousesProvider";
import { useSetCurrentProjectId } from "@/contractor/pages/home/project/hooks/useSetCurrentProjectId";
import { useProjectListOptions } from "@/contractor/pages/home/projects/hooks/useProjectListOptions";
import { AssignBuyoutButton } from "@/contractor/pages/home/release/pages/specify-details/assign-buyout/AssignBuyoutButton";
import { useRelease } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { SearchOutlined } from "@mui/icons-material";
import { FC, useCallback, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useInvoiceImportExternalPO } from "../../../../hooks/useInvoiceImportExternalPO";
import { InvoiceCreateReleaseFormValues } from "../InvoiceVerificationForm";

const Container = tw.div`w-full grid grid-row-2 gap-2 items-center z-20 p-4 -top-5 bg-blue-100 rounded-2xl drop-shadow-md grid-cols-3`;
const SearchIcon = tw(
  SearchOutlined,
)`text-blue-500 cursor-pointer focus:text-black hover:text-black -mr-1`;

export const InvoiceUpdateReleaseHeader: FC = () => {
  const intl = useIntl();
  const { release } = useRelease();
  const { connectedSourceSystem } = useOrgSettings();
  const { projects, loading: loadingProjects } = useProjectListOptions();
  const { warehouses, loading: loadingWarehouses } = useWarehouses();
  const { vendors, loading: loadingVendors } = useVendors();
  const { watch } = useFormContext<InvoiceCreateReleaseFormValues>();
  const { openModal, externalPo, loadingExternalPo, importedItems } =
    useInvoiceImportExternalPO();
  const externalPOsQueryForm = useFormContext<ExternalPOsQueryInputType>();

  const projectId = watch("projectId");
  const poNumber = watch("poNumber");

  useSetCurrentProjectId(projectId);
  const fulfillmentOptions = useMemo(() => {
    const options = [];
    const selectedProject = projects.find((p) => p.id === projectId);
    if (projectId && selectedProject) {
      options.push(selectedProject);
    }
    options.push(...warehouses);
    return options;
  }, [projectId, projects, warehouses]);

  const onPoNumberSearchIconClick = useCallback(() => {
    if (!externalPo && poNumber) {
      externalPOsQueryForm?.setValue("search", poNumber);
    }
    openModal({ skipPoList: !!externalPo });
  }, [externalPo, poNumber, externalPOsQueryForm, openModal]);

  return (
    <Container>
      <SelectControlled
        name="projectId"
        options={projects}
        getValue={(o) => o.id}
        getLabel={(o) => getProjectSelectorLabel(o)}
        placeholder={intl.$t({ id: "PROJECT_NAME" })}
        loading={loadingProjects}
        rules={{
          required: true,
        }}
      />
      <SelectControlled
        name="vendorId"
        options={vendors}
        getValue={(o) => o.sellerOrgLocation.id}
        getLabel={(o) =>
          vendorLabelFormatter(
            o.sellerOrgLocation,
            o.contacts.filter((c) => c.receivesInvoiceNotifications),
          )
        }
        placeholder={intl.$t({ id: "VENDOR" })}
        loading={loadingVendors}
        rules={{
          required: true,
        }}
        customRender={(item) =>
          VendorPickerCustomRender(item, (c) => c.receivesInvoiceNotifications)
        }
        limitResults={MAX_VENDOR_NUMBER}
      />
      <SelectControlled
        name="fulfillmentLocationId"
        options={fulfillmentOptions}
        getValue={(o) => o?.id}
        getLabel={(o) => o.name}
        placeholder={intl.$t({ id: "FULFILLMENT_LOCATION" })}
        loading={loadingWarehouses}
        rules={{
          required: true,
        }}
      />
      <PoNumberingSettingsCheck>
        <TextFieldControlled
          name="poNumber"
          label={intl.$t({ id: "PO_NUMBER" })}
          className="bg-white"
          InputProps={{
            startAdornment: (
              <ConnectionStatusAdornment
                connectedSourceSystem={connectedSourceSystem}
                loading={loadingExternalPo}
                isActive={!!externalPo}
                onClick={() => openModal({ skipPoList: !!externalPo })}
                enabledTooltip="PO_FOUND_IN_SOURCE_SYSTEM"
                disabledTooltip="PO_NOT_FOUND_IN_SOURCE_SYSTEM"
              />
            ),
            endAdornment: connectedSourceSystem ? (
              <SearchIcon onClick={onPoNumberSearchIconClick} />
            ) : null,
          }}
          disabled={!!importedItems}
        />
      </PoNumberingSettingsCheck>
      <OrderTypePickerControlled
        name="orderTypeId"
        disabled={!!importedItems}
      />
      <DatePickerControlled
        name="orderDate"
        label={intl.$t({ id: "ORDER_DATE" })}
        className="bg-white"
        maxDate={new Date()}
        rules={{
          required: true,
        }}
        utc
      />
      {release && <AssignBuyoutButton release={release} />}
    </Container>
  );
};
