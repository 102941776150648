import { TaxType, UpdateChargeInput } from "@/generated/graphql";
import { FC } from "react";
import { FormProvider, useForm, UseFormReturn } from "react-hook-form";

export type DeliverySlipReleaseFormValues = {
  businessLocationId: string;
  projectId: string;
  fulfillmentLocationId: string;
  vendorId: string;
  poNumber: string;
  orderDate: Date;
  orderTypeId: string;
  subtotal: number;
  paymentTerm: number | undefined;
  additionalCharges: UpdateChargeInput[];
  taxRate: string | undefined;
  customTaxAmount: string | undefined;
  total: string;
  taxType: TaxType | undefined;
  taxCodeId: string | undefined;
};

type DeliverySlipReleaseForm = UseFormReturn<
  DeliverySlipReleaseFormValues,
  unknown,
  DeliverySlipReleaseFormValues
>;

export const DeliverySlipVerificationForm: FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const deliverySlipReleaseForm: DeliverySlipReleaseForm =
    useForm<DeliverySlipReleaseFormValues>({
      defaultValues: {
        projectId: "",
        vendorId: "",
        poNumber: "",
        orderDate: undefined,
        subtotal: 0,
        paymentTerm: undefined,
        customTaxAmount: "0",
        additionalCharges: [],
        total: "0",
        orderTypeId: "",
      },
      mode: "onChange",
      reValidateMode: "onChange",
    });

  return <FormProvider {...deliverySlipReleaseForm}>{children}</FormProvider>;
};
