import { OutlinedButton } from "@/common/components/button/OutlinedButton";
import { PrimaryButton } from "@/common/components/button/PrimaryButton";
import { WarningIcon } from "@/common/components/dialog-icons/WarningIcon";
import { useDialog } from "@/common/components/dialog/DialogProvider";
import { FloatingFooter } from "@/common/components/footer/FloatingFooter";
import { routes } from "@/config/routes";
import { SubmitBuyoutDialog } from "@/contractor/pages/home/buyout/components/non-quoted/providers/submit-buyout-dialog/SubmitBuyoutDialog";
import { useBuyoutMutations } from "@/contractor/pages/home/buyout/components/non-quoted/providers/useBuyoutMutations";
import { useQuoteDocument } from "@/contractor/pages/home/common/quote-document/providers/QuoteDocumentProvider";
import { FC, useCallback, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import tw from "tailwind-styled-components";
import { useBuyoutFromQuote } from "../../../../providers/BuyoutFromQuoteProvider";
import { CreateBuyoutFromQuoteFormValues } from "./CreateBuyoutFromQuoteForm";

const ButtonContainer = tw.div`grid grid-flow-col gap-2 items-center justify-end`;
const FloatingFooterStyled = tw(FloatingFooter)`md:px-6`;

export const CreateBuyoutFromQuoteFooter: FC = () => {
  const navigate = useNavigate();
  const { quoteDocument } = useQuoteDocument();
  const {
    syncCreateBuyoutFromQuote,
    creating,
    syncUpdateBuyoutFromQuote,
    updating,
    vendors,
  } = useBuyoutFromQuote();
  const { buyoutId } = useParams();
  const { submitBuyout, submitting } = useBuyoutMutations();
  const { openDialog } = useDialog();
  const intl = useIntl();
  const [isSaving, setIsSaving] = useState(false);
  const { handleSubmit, reset, watch } =
    useFormContext<CreateBuyoutFromQuoteFormValues>();
  const [submitDialogVisible, setSubmitDialogVisible] = useState(false);

  const handleCreateBuyoutFromQuote = useCallback(
    async (skipConfirmation: boolean, skipVendorNotification: boolean) => {
      return await handleSubmit(async () => {
        if (buyoutId) {
          if (await syncUpdateBuyoutFromQuote()) {
            const result = await submitBuyout({
              buyoutId,
              skipConfirmation,
              skipVendorNotification,
            });
            if (result) {
              navigate(
                generatePath(routes.buyout, {
                  id: buyoutId,
                }),
              );
            }
            return result;
          }
        } else {
          const result = await syncCreateBuyoutFromQuote();
          const submittedResult = await submitBuyout({
            buyoutId: result as string,
            skipConfirmation,
            skipVendorNotification,
          });
          if (!submittedResult) {
            return null;
          }
          navigate(
            generatePath(routes.buyout, {
              id: result as string,
            }),
          );
          return result;
        }
      })();
    },
    [
      handleSubmit,
      buyoutId,
      syncUpdateBuyoutFromQuote,
      submitBuyout,
      navigate,
      syncCreateBuyoutFromQuote,
    ],
  );

  const handleSaveBuyoutFromQuote = useCallback(async () => {
    await handleSubmit(async () => {
      setIsSaving(true);
      if (buyoutId) {
        await syncUpdateBuyoutFromQuote();
      } else {
        const result = await syncCreateBuyoutFromQuote();
        if (result) {
          navigate(
            generatePath(routes.editBuyoutFromQuote, {
              quoteDocumentId: quoteDocument?.id,
              buyoutId: result,
            }),
          );
        }
      }
      setIsSaving(false);
    })();
  }, [
    handleSubmit,
    buyoutId,
    syncUpdateBuyoutFromQuote,
    syncCreateBuyoutFromQuote,
    navigate,
    quoteDocument?.id,
  ]);

  const selectedVendorId = watch("vendorId");
  const selectedVendor = useMemo(
    () => vendors.find((v) => v.sellerOrgLocation.id === selectedVendorId),
    [selectedVendorId, vendors],
  );

  const handleCreate = useCallback(() => {
    if (selectedVendor && selectedVendor.contacts.length === 0) {
      openDialog({
        cancelButtonText: intl.$t({ id: "CANCEL" }),
        confirmButtonText: intl.$t({ id: "CONFIRM" }),
        icon: <WarningIcon />,
        title: intl.$t({ id: "CREATE_BUYOUT_WITHOUT_VENDOR_CONFIRMATION" }),
        text: intl.$t({
          id: "CREATE_BUYOUT_WITHOUT_VENDOR_CONFIRMATION_DESCRIPTION",
        }),
        handleConfirm: () => setSubmitDialogVisible(true),
      });
    } else {
      setSubmitDialogVisible(true);
    }
  }, [selectedVendor, openDialog, intl]);

  return (
    <FloatingFooterStyled>
      <ButtonContainer>
        <OutlinedButton
          className="py-0"
          onClick={() => {
            reset();
            navigate(routes.buyouts);
          }}
        >
          <FormattedMessage id="CANCEL" />
        </OutlinedButton>
        <OutlinedButton
          className="py-0"
          onClick={handleSaveBuyoutFromQuote}
          loading={isSaving && (creating || updating)}
          disabled={!isSaving && (creating || updating)}
        >
          <FormattedMessage id="SAVE" />
        </OutlinedButton>
        <PrimaryButton
          className="py-0"
          onClick={handleCreate}
          loading={!isSaving && (creating || updating)}
          disabled={isSaving && (creating || updating)}
        >
          <FormattedMessage
            id={
              selectedVendor && selectedVendor.contacts.length === 0
                ? "CREATE_BUYOUT"
                : "SUBMIT"
            }
          />
        </PrimaryButton>
      </ButtonContainer>
      <SubmitBuyoutDialog
        visible={submitDialogVisible}
        setVisible={setSubmitDialogVisible}
        callback={handleCreateBuyoutFromQuote}
        saving={submitting || updating || creating}
      />
    </FloatingFooterStyled>
  );
};
