import { If } from "@/common/components/if/If";
import { OverlayPanel } from "@/common/components/panel/OverlayPanel";
import { Select } from "@/common/components/select/components/single/Select";
import { TextField } from "@/common/components/textfield/TextField";
import { useSnackbar } from "@/common/providers/SnackbarProvider";
import { routes } from "@/config/routes";
import {
  AssetFieldsFragment,
  BuyoutFieldsFragment,
  ProjectFieldsFragment,
} from "@/generated/graphql";

import { LocationSelector } from "@/common/components/location-selector/LocationSelector";
import { useOrderTypeOptions } from "@/common/components/order-type-picker/hooks/useOrderTypeOptions";
import { usePoNumberingSettingsCheck } from "@/common/components/po-numbering-settings-check/usePoNumberingSettingsCheck";
import { getProjectSelectorLabel } from "@/common/components/projects-filter-selector/getProjectSelectorLabel";
import {
  VendorPickerCustomRender,
  vendorLabelFormatter,
} from "@/common/components/vendor-picker/VendorPickerCustomRender";
import { useVendors } from "@/common/components/vendors/hooks/useVendors";
import { MAX_VENDOR_NUMBER } from "@/common/const";
import { useProjectListOptions } from "@/contractor/pages/home/projects/hooks/useProjectListOptions";
import { FormControl } from "@mui/material";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { generatePath, useNavigate } from "react-router-dom";
import tw from "tailwind-styled-components";
import { useBuyoutMutations } from "../../../buyout/components/non-quoted/providers/useBuyoutMutations";
import { QuoteDocumentImport } from "../../../common/quote-document/components/QuoteDocumentImport";
import {
  QuoteDocumentProvider,
  useQuoteDocument,
} from "../../../common/quote-document/providers/QuoteDocumentProvider";

const FormContainer = tw.div`
  flex flex-col w-full
`;

const FormControlStyled = tw(FormControl)`
  my-2
`;

const Label = tw.div`
  w-full text-gray-600 font-normal
`;

type CustomProjectType = Pick<ProjectFieldsFragment, "id" | "name">;

type Props = {
  onClose: () => void;
  label: string;
  project?: CustomProjectType | null;
  buyout?: BuyoutFieldsFragment | null;
};

enum NewBuyoutType {
  CreateNew = "createNew",
  Import = "import",
}

const tabs = [
  {
    label: <FormattedMessage id="CREATE_NEW" />,
    viewState: NewBuyoutType.CreateNew,
  },
  {
    label: (
      <FormattedMessage id="IMPORT_FROM_VENDOR_QUOTE" values={{ br: <br /> }} />
    ),
    viewState: NewBuyoutType.Import,
    info: <FormattedMessage id="IMPORT_FROM_VENDOR_QUOTE_INFO" />,
  },
];

const NewBuyoutFormWithProvider: FC<Props> = ({
  onClose,
  label,
  project,
  buyout,
}) => {
  const intl = useIntl();
  const { includePoNumbering } = usePoNumberingSettingsCheck();
  const { projects, loading } = useProjectListOptions();
  const { vendors, loading: loadingVendors } = useVendors();
  const {
    orderTypes,
    loading: loadingTypes,
    defaultOrderType,
  } = useOrderTypeOptions();
  const { createBuyout, updateContractorBuyout } = useBuyoutMutations();
  const { setSuccessAlert } = useSnackbar();
  const [asset, setAsset] = useState<AssetFieldsFragment | null>(null);
  const [locationId, setLocationId] = useState<string | null>(null);
  const [projectId, setProjectId] = useState<string | null>(
    project?.id || null,
  );
  const [orderType, setOrderType] = useState<string | null>(
    defaultOrderType?.id || null,
  );
  const [vendorId, setVendorId] = useState<string | null>(
    buyout?.sellerOrgLocation?.id || null,
  );
  const [poNumber, setPoNumber] = useState<string | null>(
    buyout?.poNumber || "",
  );
  const [view, setView] = useState(NewBuyoutType.CreateNew);
  const { createQuoteDocumentWithAsset } = useQuoteDocument();

  const navigate = useNavigate();

  const onProjectChange = useCallback((projectId: string | null) => {
    setProjectId(projectId);
  }, []);

  useEffect(() => {
    if (!orderType && defaultOrderType) {
      setOrderType(defaultOrderType.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  const onSubmit = async () => {
    if (!projectId || !vendorId) {
      return;
    }

    if (buyout?.id) {
      const result = await updateContractorBuyout({
        buyoutId: buyout.id,
        version: buyout.version,
        poNumber,
        vendorLocationId: vendorId,
      });
      if (result) {
        onClose();
        setSuccessAlert(
          intl.$t(
            { id: "BUYOUT_UPDATE_SUCCESS" },
            { number: buyout.clientIdentifier },
          ),
        );
      }
      return;
    }

    const result = await createBuyout({
      projectId,
      vendorLocationId: vendorId,
      releaseTypeId: orderType || undefined,
      poNumber: poNumber || undefined,
      mergeDuplicates: false,
    });
    if (result) {
      onClose();
      setSuccessAlert(
        intl.$t({ id: "BUYOUT_SUCCESS" }, { number: result.clientIdentifier }),
      );
      navigate(generatePath(routes.buyout, { id: result.id }));
    }
  };

  const onTabChange = useCallback((viewState: string) => {
    setView(viewState as NewBuyoutType);
  }, []);

  const createFromQuote = async () => {
    const result = await createQuoteDocumentWithAsset(
      locationId ||
        projects.find((proj) => proj.id === project?.id)?.location.id ||
        "",
    );
    if (result) {
      navigate({
        pathname: generatePath(routes.buyoutFromQuote, {
          quoteDocumentId: result?.id,
        }),
        search: projectId ? `?projectId=${projectId}` : "",
      });
    }
  };

  const saveLabel = useMemo(() => {
    if (view === NewBuyoutType.CreateNew) {
      if (buyout) {
        return "UPDATE";
      }
      return "CREATE";
    }
    return "IMPORT";
  }, [buyout, view]);

  return (
    <OverlayPanel
      title={label}
      onSave={view === NewBuyoutType.CreateNew ? onSubmit : createFromQuote}
      saveLabel={intl.$t({
        id: saveLabel,
      })}
      onCancel={onClose}
      disableSave={
        (view === NewBuyoutType.Import &&
          ((!locationId && !project) || !asset)) ||
        (view === NewBuyoutType.CreateNew && (!projectId || !vendorId))
      }
      flexDirectionRow
      tabs={tabs}
      activeTab={view}
      onTabChange={onTabChange}
    >
      <If isTrue={view === NewBuyoutType.CreateNew}>
        <FormContainer>
          <FormControlStyled fullWidth>
            <Select
              placeholder={intl.$t({
                id: "BUYOUT_NEW_PROJECT",
              })}
              testId="projectId"
              options={project?.id ? [project] : projects}
              staticText={!!project?.id}
              className={project?.id ? "ml-3" : ""}
              value={projectId}
              onChange={onProjectChange}
              getLabel={(option) => getProjectSelectorLabel(option)}
              getValue={(option) => option.id}
              required
              disabled={loading}
              noOptionsText={intl.$t({
                id: "NO_PROJECTS_IN_LOCATION",
              })}
            />
          </FormControlStyled>
          <If isTrue={projectId}>
            <FormControlStyled fullWidth>
              <Select
                placeholder={intl.$t({ id: "BUYOUT_NEW_VENDOR" })}
                options={vendors || []}
                loading={loadingVendors}
                value={vendorId}
                onChange={setVendorId}
                customRender={(item) =>
                  VendorPickerCustomRender(
                    item,
                    (c) => c.receivesBuyoutNotifications,
                  )
                }
                getLabel={(o) =>
                  vendorLabelFormatter(
                    o.sellerOrgLocation,
                    o.contacts.filter((c) => c.receivesBuyoutNotifications),
                  )
                }
                getOptionDisabled={(option) => option.contacts.length === 0}
                disabledTooltip={intl.$t({ id: "VENDOR_DISABLED_TOOLTIP" })}
                getValue={(option) => option.sellerOrgLocation.id}
                testId="vendorId"
                required
                limitResults={MAX_VENDOR_NUMBER}
              />
            </FormControlStyled>
            <FormControlStyled fullWidth>
              <Select
                placeholder={intl.$t({ id: "ORDER_TYPE" })}
                options={orderTypes || []}
                value={orderType}
                onChange={setOrderType}
                getLabel={(option) => option.name}
                getValue={(option) => option.id}
                testId="buyout-form-order-type-selector"
                loading={loadingTypes}
                required
              />
            </FormControlStyled>
          </If>
          <If isTrue={vendorId}>
            <If isTrue={includePoNumbering}>
              <Label>
                <FormattedMessage id="BUYOUT_NEW_PO" />
              </Label>
              <FormControlStyled fullWidth>
                <TextField
                  size="small"
                  label={intl.$t({ id: "PO_HASH" })}
                  value={poNumber}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setPoNumber(event.target.value)
                  }
                  testId="buyout-form-po-number"
                />
              </FormControlStyled>
            </If>
          </If>
        </FormContainer>
      </If>
      <If isTrue={view === NewBuyoutType.Import}>
        <FormContainer>
          <If isTrue={!project?.id}>
            <FormControlStyled fullWidth>
              <LocationSelector
                locationId={locationId}
                setLocationId={setLocationId}
              />
            </FormControlStyled>
          </If>
          <QuoteDocumentImport onChange={setAsset} />
        </FormContainer>
      </If>
    </OverlayPanel>
  );
};

export const NewBuyoutForm: FC<Props> = (props) => {
  return (
    <QuoteDocumentProvider>
      <NewBuyoutFormWithProvider {...props} />
    </QuoteDocumentProvider>
  );
};
