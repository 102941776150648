import { InstructionInput } from "@/generated/graphql";
import { FC } from "react";
import { FormProvider, useForm, UseFormReturn } from "react-hook-form";

export type CreateBuyoutFromQuoteFormValues = {
  businessLocationId: string;
  projectId: string;
  vendorId: string;
  releaseTypeId: string;
  poNumber: string;
  orderDate: Date;
  subtotal: string;
  paymentTerm: number | undefined;
  taxRate: string | undefined;
  customTaxAmount: string | undefined;
  clearCustomTaxAmount: boolean;
  total: string;
  vendorContactIds: string[];
  instructions: InstructionInput | undefined;
  description: string | null | undefined;
};

type CreateBuyoutForm = UseFormReturn<
  CreateBuyoutFromQuoteFormValues,
  unknown,
  CreateBuyoutFromQuoteFormValues
>;

export const CreateBuyoutFromQuoteForm: FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const createBuyoutFromQuoteForm: CreateBuyoutForm =
    useForm<CreateBuyoutFromQuoteFormValues>({
      defaultValues: {
        businessLocationId: "",
        projectId: "",
        vendorId: "",
        releaseTypeId: "",
        poNumber: "",
        description: undefined,
        orderDate: undefined,
        customTaxAmount: "0",
        taxRate: undefined,
        subtotal: "0",
        total: "0",
        instructions: {
          text: "",
          assetUrls: [],
        },
      },
      mode: "onChange",
      reValidateMode: "onChange",
    });

  return <FormProvider {...createBuyoutFromQuoteForm}>{children}</FormProvider>;
};
