import DateRangePicker from "@/common/components/date-range-picker/DateRangePicker";
import { DateRangePickerValue } from "@/common/components/date-range-picker/types";
import { useIntegrationFeatureRequirement } from "@/common/components/integration-feature-requirement/hooks/useIntegrationFeatureRequirement";
import { getProjectSelectorLabel } from "@/common/components/projects-filter-selector/getProjectSelectorLabel";
import { SearchInput } from "@/common/components/search-input/SearchInput";
import { SelectControlled } from "@/common/components/select/components/single/SelectControlled";
import { VendorPickerCustomRender } from "@/common/components/vendor-picker/VendorPickerCustomRender";
import { useVendors } from "@/common/components/vendors/hooks/useVendors";
import { IntegrationFeature } from "@/common/hooks/integrations/types/IntegrationFeature";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { useProjectListOptions } from "@/contractor/pages/home/projects/hooks/useProjectListOptions";
import { useCallback, useEffect, useMemo } from "react";
import { FormProvider } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useDeliverySlipImportExternalPO } from "../../../../providers/DeliverySlipImportExternalPOProvider";

const Row = tw.div`flex flex-row items-center`;
const Container = tw(
  Row,
)`justify-between items-center bg-blue-100 rounded-2xl h-16 px-5 mb-4`;
const Title = tw.div`text-lg font-medium mr-4`;

export const DeliverySlipExternalPOsTableHeader = () => {
  const intl = useIntl();
  const { connectedSourceSystem } = useOrgSettings();
  const { externalPOsQueryForm } = useDeliverySlipImportExternalPO();
  const { projects, loading: loadingProjects } = useProjectListOptions();
  const { vendors, loading: loadingVendors } = useVendors();
  const { hasFeatureInConnectedSourceSystem } =
    useIntegrationFeatureRequirement();

  const filteredProjects = useMemo(
    () =>
      projects.filter((v) =>
        v.externalProjects.some(
          (m) => m.sourceSystem === connectedSourceSystem,
        ),
      ),
    [projects, connectedSourceSystem],
  );

  const filteredVendors = useMemo(
    () =>
      vendors.filter((v) =>
        v.externalVendors.some((m) => m.sourceSystem === connectedSourceSystem),
      ),
    [vendors, connectedSourceSystem],
  );

  const minDate = externalPOsQueryForm?.watch("minDate");
  const maxDate = externalPOsQueryForm?.watch("maxDate");

  const selectedDateRangeValue = useMemo(() => {
    if (minDate || maxDate) {
      return {
        startDate: minDate ? new Date(minDate) : new Date(),
        endDate: maxDate ? new Date(maxDate) : new Date(),
      };
    }
    return undefined;
  }, [minDate, maxDate]);

  const handleChangeDateRange = useCallback(
    (dateRange: DateRangePickerValue) => {
      externalPOsQueryForm?.setValue("minDate", dateRange.startDate?.getTime());
      externalPOsQueryForm?.setValue("maxDate", dateRange.endDate?.getTime());
    },
    [externalPOsQueryForm],
  );

  const isProjectSelectionRequired = useMemo(
    () =>
      hasFeatureInConnectedSourceSystem(
        IntegrationFeature.ExternalPoReadsProjectSpecific,
      ),
    [hasFeatureInConnectedSourceSystem],
  );

  const projectId = externalPOsQueryForm?.watch("projectId");
  useEffect(() => {
    if (!projectId && isProjectSelectionRequired) {
      externalPOsQueryForm?.setError("projectId", {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  if (!externalPOsQueryForm) {
    return null;
  }
  return (
    <Container>
      <FormProvider {...externalPOsQueryForm}>
        <Row>
          <FormattedMessage
            id="IMPORT_PO_FROM_SOURCE_SYSTEM"
            tagName={Title}
            values={{
              sourceSystem: intl.$t({
                id: `INTEGRATION_${connectedSourceSystem}`,
              }),
            }}
          />
          <SelectControlled
            name="projectId"
            label={intl.$t({ id: "PROJECT" })}
            required={isProjectSelectionRequired}
            options={filteredProjects}
            getLabel={(p) => getProjectSelectorLabel(p)}
            getValue={(p) => p.id}
            loading={loadingProjects}
            className="mr-2"
            disableClearable={false}
          />
          <SelectControlled
            name="sellerOrgLocationID"
            label={intl.$t({ id: "VENDOR" })}
            options={filteredVendors}
            getLabel={(v) => v.sellerOrgLocation.name}
            getValue={(v) => v.sellerOrgLocation.id}
            loading={loadingVendors}
            customRender={VendorPickerCustomRender}
            className="mr-2"
            disableClearable={false}
          />
          <DateRangePicker
            selectedValue={selectedDateRangeValue}
            isUTC
            onChange={handleChangeDateRange}
            titleId="SELECT_EXTERNAL_PO_DATE"
          />
        </Row>
        <Row>
          <SearchInput
            placeHolder={intl.$t({ id: "SEARCH_PO_SYMBOL" })}
            value={externalPOsQueryForm.watch("search")}
            onChange={(value) => externalPOsQueryForm.setValue("search", value)}
          />
        </Row>
      </FormProvider>
    </Container>
  );
};
