import { useTableHelpers } from "@/common/components/spreadsheet-table/hooks/useTableHelpers";
import { useTableValidators } from "@/common/components/spreadsheet-table/hooks/useTableValidators";
import { useGlobalError } from "@/common/hooks/useGlobalError";
import {
  COLUMN_TYPE,
  useColumnMapper,
} from "@/common/providers/ColumnMapperProvider";
import {
  CreateStandaloneReleaseInput,
  DeliverySlipsDocument,
  OrgPreferredVendorsFieldsFragment,
  ReleaseFieldsFragment,
  ReleaseStatus,
  ServiceType,
  UomsDocument,
  UpdateContractorReleaseInput,
  UpdateContractorReleaseItemInput,
  useCreateStandaloneReleaseMutation,
  useProjectPredictedPoNumberQuery,
  useUpdateContractorReleaseMutation,
} from "@/generated/graphql";
import { FC, createContext, useContext, useEffect, useState } from "react";

import { useVendors } from "@/common/components/vendors/hooks/useVendors";
import { useSnackbar } from "@/common/providers/SnackbarProvider";
import {
  TableViewState,
  useTableViewStore,
} from "@/common/stores/useTableViewStore";
import { routes } from "@/config/routes";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { useQuoteDocument } from "@/contractor/pages/home/common/quote-document/providers/QuoteDocumentProvider";
import { useLumpSumReleaseItems } from "@/contractor/pages/home/release/hooks/useLumpSumReleaseItems";
import { useSyncReleaseItems } from "@/contractor/pages/home/release/pages/specify-details/hooks/useSyncReleaseItems";
import { useRelease } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { NoFunction, NoFunctionUndefined } from "@/types/NoFunction";
import { useFormContext } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { generatePath, useNavigate } from "react-router-dom";
import { useShallow } from "zustand/react/shallow";
import { CreateOrderFromQuoteFormValues } from "../components/order-from-quote/create-order-from-quote/components/CreateOrderFromQuoteForm";

export type CreateReleaseFromQuoteArgs = {
  skipVendorNotification?: boolean;
  skipConfirmation?: boolean;
  retroactive?: boolean;
  status?: ReleaseStatus;
};

type ProviderContextType = {
  syncCreateReleaseFromQuote: (
    args: CreateReleaseFromQuoteArgs,
  ) => Promise<ReleaseFieldsFragment | undefined | null>;
  syncUpdateReleaseFromQuote: () => Promise<
    ReleaseFieldsFragment | undefined | null
  >;
  validateSpreadsheet: () => boolean;
  loading: boolean;
  vendors: OrgPreferredVendorsFieldsFragment[];
  loadingVendors: boolean;
  findOrderTypeByLocationId: (locationId: string) => string | undefined;
  loadingPredictedPoNumber: boolean;
  itemized: boolean;
  setItemized: (isItemized: boolean) => void;
  createLumpSumRelease: (
    args: CreateReleaseFromQuoteArgs,
  ) => Promise<ReleaseFieldsFragment | undefined | null>;
  updateLumpSumRelease: () => Promise<ReleaseFieldsFragment | undefined | null>;
};

const ProviderContext = createContext<ProviderContextType>({
  syncCreateReleaseFromQuote: () => Promise.resolve(null),
  syncUpdateReleaseFromQuote: () => Promise.resolve(null),
  validateSpreadsheet: () => false,
  loading: false,
  vendors: [],
  loadingPredictedPoNumber: false,
  loadingVendors: false,
  findOrderTypeByLocationId: NoFunctionUndefined,
  itemized: true,
  setItemized: NoFunction,
  createLumpSumRelease: () => Promise.resolve(null),
  updateLumpSumRelease: () => Promise.resolve(null),
});

export const OrderFromQuoteProvider: FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const navigate = useNavigate();
  const { quoteDocument } = useQuoteDocument();
  const { setError } = useGlobalError();
  const { release } = useRelease();
  const { validateRequiredValues, validateRowValues } = useTableValidators();
  const { spreadsheetData, resetPreviousData, gotoInvalidRow } =
    useColumnMapper();
  const { rowIsEmpty } = useTableHelpers();
  const { getSyncedRelease, saving } = useSyncReleaseItems();
  const {
    vendors,
    loading: loadingVendors,
    findOrderTypeByLocationId,
  } = useVendors();
  const { setWarningAlert } = useSnackbar();
  const [itemized, setItemized] = useState(true);
  const { initViewStore, resetViewStore, setViewState } = useTableViewStore(
    useShallow((state) => ({
      initViewStore: state.initViewStore,
      resetViewStore: state.resetViewStore,
      setViewState: state.setViewState,
    })),
  );
  const { hasPhaseCodes } = useOrgSettings();
  const { getLumpSumReleaseItem } = useLumpSumReleaseItems();

  const { setValue, watch, getValues } =
    useFormContext<CreateOrderFromQuoteFormValues>();

  const projectId = watch("projectId");
  const poNumber = watch("poNumber");
  const orderDate = watch("orderDate");

  const {
    data: projectData,
    error: predictedPoNumberError,
    loading: loadingPredictedPoNumber,
  } = useProjectPredictedPoNumberQuery({
    variables: {
      id: projectId,
    },
    skip: !projectId || !!poNumber,
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    initViewStore(TableViewState.spreadsheet);
    return () => {
      resetViewStore();
    };
  }, [initViewStore, resetViewStore]);

  useEffect(() => {
    setViewState(itemized ? TableViewState.spreadsheet : TableViewState.normal);
  }, [itemized, setViewState]);

  useEffect(() => {
    const predictedPoNumber = projectData?.project?.predictedPoNumber;

    if (!predictedPoNumberError && predictedPoNumber) {
      return setValue("predictedPoNumber", predictedPoNumber);
    }

    return setValue("predictedPoNumber", "");
  }, [
    orderDate,
    projectData?.project?.predictedPoNumber,
    predictedPoNumberError,
    setValue,
  ]);

  const [createStandaloneRelease, { loading: creating }] =
    useCreateStandaloneReleaseMutation();
  const [updateReleaseMutation, { loading: updating }] =
    useUpdateContractorReleaseMutation();

  const validateSpreadsheet = () => {
    if (spreadsheetData.every((row) => rowIsEmpty(row))) {
      setWarningAlert(
        <FormattedMessage id={`VALIDATION_ERROR_SHEETS_EMPTY_LIST`} />,
      );
      return false;
    }

    if (
      !validateRequiredValues([
        COLUMN_TYPE.Material,
        COLUMN_TYPE.UOM,
        COLUMN_TYPE.Quantity,
      ]) ||
      !validateRowValues([
        COLUMN_TYPE.Quantity,
        COLUMN_TYPE.UOM,
        ...(hasPhaseCodes ? [COLUMN_TYPE.PhaseCode] : [COLUMN_TYPE.CostCode]),
      ])
    ) {
      gotoInvalidRow();
      return false;
    }

    return true;
  };

  const createRelease = async (input: CreateStandaloneReleaseInput) => {
    try {
      const { data, errors } = await createStandaloneRelease({
        variables: {
          input,
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: UomsDocument,
          },
          {
            query: DeliverySlipsDocument,
          },
        ],
      });
      setError(errors);
      if (!errors) {
        resetPreviousData();
      }
      return data?.createStandaloneRelease ?? null;
    } catch (error) {
      setError(error);
      return null;
    }
  };

  const updateRelease = async (input: UpdateContractorReleaseInput) => {
    try {
      const { data, errors } = await updateReleaseMutation({
        variables: {
          input,
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: UomsDocument,
          },
          {
            query: DeliverySlipsDocument,
          },
        ],
      });
      setError(errors);
      if (!errors) {
        resetPreviousData();
      }
      return data?.updateContractorRelease ?? null;
    } catch (error) {
      setError(error);
      return null;
    }
  };

  const syncCreateReleaseFromQuote = async ({
    skipVendorNotification,
    skipConfirmation,
    retroactive,
    status,
  }: CreateReleaseFromQuoteArgs) => {
    const isValid = validateSpreadsheet();
    const values = getValues();

    if (!isValid) {
      return null;
    }

    const { addedItems } = await getSyncedRelease();

    if (addedItems.length > 0) {
      const includeServices = [];
      if (!values.willCall) {
        includeServices.push({ type: ServiceType.Delivery });
      }
      if (!values.willCall && values.vendorStocking) {
        includeServices.push({ type: ServiceType.Stocking });
      }

      const relStatus =
        values.orderDate?.getTime() < Date.now()
          ? retroactive
            ? ReleaseStatus.Received
            : ReleaseStatus.Scheduled
          : skipConfirmation
            ? ReleaseStatus.Scheduled
            : skipConfirmation === false
              ? ReleaseStatus.Requested
              : ReleaseStatus.Draft;

      return await createRelease({
        projectId: values.projectId ?? "",
        sellerOrgLocationId: values.vendorId,
        poNumber: values.poNumber || undefined,
        time: values.orderDate?.getTime() ?? undefined,
        items: addedItems,
        requiresInventoryReceipt: values.requiresInventoryReceipt,
        quoteDocumentId: quoteDocument?.id ?? "",
        includeServices,
        taxRate:
          values.taxRate ||
          (values.clearCustomTaxAmount || values.customTaxAmount?.length === 0
            ? "0"
            : undefined),
        customTaxAmount:
          values.clearCustomTaxAmount || values.customTaxAmount?.length === 0
            ? undefined
            : values.customTaxAmount,
        additionalCharges: values.additionalCharges,
        instructions: values.instructions,
        paymentTerm: values.paymentTerm,
        status: status || relStatus,
        typeId: values.orderTypeId || undefined,
        vendorContactIds: values.vendorContactIds,
        taxCodeId: values.taxCodeId,
        taxType: values.taxType,
        warehouseId: values.willCall
          ? undefined
          : values.projectId !== values.fulfillmentLocationId
            ? values.fulfillmentLocationId
            : undefined,
        skipVendorNotification,
      });
    }
  };

  const syncUpdateReleaseFromQuote = async () => {
    const values = getValues();
    if (
      !validateRequiredValues([
        COLUMN_TYPE.Material,
        COLUMN_TYPE.UOM,
        COLUMN_TYPE.Quantity,
      ]) ||
      !validateRowValues(
        [COLUMN_TYPE.Quantity, COLUMN_TYPE.UOM, COLUMN_TYPE.CostCode],
        undefined,
        { minPrice: undefined },
      )
    ) {
      gotoInvalidRow();
      return null;
    }

    const { addedItems, updates, removedItems } = await getSyncedRelease();

    const includeServices = [];
    if (!values.willCall) {
      includeServices.push({ type: ServiceType.Delivery });
    }
    if (!values.willCall && values.vendorStocking) {
      includeServices.push({ type: ServiceType.Stocking });
    }

    if (release) {
      return await updateRelease({
        releaseId: release.id,
        version: release.version,
        sellerOrgLocationId: values.vendorId,
        addedItems,
        updates,
        removedItems,
        taxRate: values.taxRate || undefined,
        customTaxAmount: values.customTaxAmount || undefined,
        clearCustomTaxAmount: !values.customTaxAmount,
        additionalCharges: values.additionalCharges.filter(
          (charge) => charge.description && Number(charge.amount) > 0,
        ),
        poNumber: values.poNumber,
        requestedTime: values.orderDate?.getTime() ?? undefined,
        requiresInventoryReceipt: values.requiresInventoryReceipt,
        includeServices,
        instructions: values.instructions,
        typeId: values.orderTypeId || undefined,
        assignDefaultCostCodes: false,
        prefillPrices: false,
        warehouseId: values.willCall
          ? undefined
          : values.projectId !== values.fulfillmentLocationId
            ? values.fulfillmentLocationId
            : undefined,
      });
    }

    return release;
  };

  const createLumpSumRelease = async ({
    skipVendorNotification,
    skipConfirmation,
    retroactive,
    status,
  }: CreateReleaseFromQuoteArgs) => {
    const values = getValues();

    const items = await getLumpSumReleaseItem({
      ...values,
      subtotal: Number(values.subtotal || 0),
    });

    const relStatus =
      values.orderDate?.getTime() < Date.now()
        ? retroactive
          ? ReleaseStatus.Received
          : ReleaseStatus.Scheduled
        : skipConfirmation
          ? ReleaseStatus.Scheduled
          : skipConfirmation === false
            ? ReleaseStatus.Requested
            : ReleaseStatus.Draft;

    const data = await createRelease({
      projectId: values.projectId ?? "",
      sellerOrgLocationId: values.vendorId,
      poNumber: values.poNumber || undefined,
      time: values.orderDate?.getTime() ?? undefined,
      items,
      requiresInventoryReceipt: values.requiresInventoryReceipt,
      quoteDocumentId: quoteDocument?.id ?? "",
      taxRate:
        values.taxRate ||
        (values.clearCustomTaxAmount || values.customTaxAmount?.length === 0
          ? "0"
          : undefined),
      customTaxAmount:
        values.clearCustomTaxAmount || values.customTaxAmount?.length === 0
          ? undefined
          : values.customTaxAmount,
      additionalCharges: values.additionalCharges,
      instructions: values.instructions,
      paymentTerm: values.paymentTerm,
      status: status || relStatus,
      typeId: values.orderTypeId || undefined,
      vendorContactIds: values.vendorContactIds,
      taxCodeId: values.taxCodeId,
      taxType: values.taxType,
      assignDefaultCostCodes: false,
      skipVendorNotification,
    });

    if (data?.id) {
      navigate(
        generatePath(routes.delivery, {
          deliveryId: data.id,
        }),
      );
    }

    return data;
  };

  const updateLumpSumRelease = async () => {
    const values = getValues();
    const includeServices = [];
    if (!values.willCall) {
      includeServices.push({ type: ServiceType.Delivery });
    }
    if (!values.willCall && values.vendorStocking) {
      includeServices.push({ type: ServiceType.Stocking });
    }
    const newItems = await getLumpSumReleaseItem({
      ...values,
      subtotal: Number(values.subtotal || 0),
    });
    const itemsToUpdate: UpdateContractorReleaseItemInput[] = [];
    const itemsToRemove = release?.items.map((item) => item.id);
    if (release) {
      return await updateRelease({
        releaseId: release.id,
        version: release.version,
        sellerOrgLocationId: values.vendorId,
        addedItems: newItems,
        updates: itemsToUpdate,
        removedItems: itemsToRemove,
        taxRate: values.taxRate || undefined,
        customTaxAmount: values.customTaxAmount || undefined,
        clearCustomTaxAmount: !values.customTaxAmount,
        additionalCharges: values.additionalCharges.filter(
          (charge) => charge.description && Number(charge.amount) > 0,
        ),
        poNumber: values.poNumber,
        requestedTime: values.orderDate?.getTime() ?? undefined,
        requiresInventoryReceipt: values.requiresInventoryReceipt,
        includeServices,
        instructions: values.instructions,
        typeId: values.orderTypeId || undefined,
        assignDefaultCostCodes: false,
        prefillPrices: false,
      });
    }
  };

  return (
    <ProviderContext.Provider
      value={{
        syncCreateReleaseFromQuote,
        syncUpdateReleaseFromQuote,
        validateSpreadsheet,
        loading: saving || creating || updating,
        vendors,
        loadingVendors,
        findOrderTypeByLocationId,
        loadingPredictedPoNumber,
        itemized,
        setItemized,
        createLumpSumRelease,
        updateLumpSumRelease,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useOrderFromQuote = (): ProviderContextType =>
  useContext(ProviderContext);
