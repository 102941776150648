import { BulkCostCodeSelector } from "@/common/components/cost-code-selector/BulkCostCodeSelector";
import { BuyoutStatus } from "@/generated/graphql";
import { FC, useCallback } from "react";
import { useContractorBuyout } from "../../../providers/ContractorBuyoutProvider";

type Props = {
  setLoading: (loading: boolean) => void;
};

export const BuyoutBulkCostCodeSelector: FC<Props> = ({ setLoading }) => {
  const { buyout, selectedBuyoutItems, setSelectedBuyoutItems, updateBuyout } =
    useContractorBuyout();

  const updateCostCode = useCallback(
    async (code: string | null) => {
      if (!buyout) {
        return;
      }
      await updateBuyout(
        {
          updates: selectedBuyoutItems.map((id) => ({
            buyoutItemId: id,
            costCodeId: code,
            clearCostCode: !code,
          })),
        },
        buyout?.status === BuyoutStatus.Active,
      );
      setSelectedBuyoutItems([]);
    },
    [buyout, selectedBuyoutItems, setSelectedBuyoutItems, updateBuyout],
  );

  return (
    <BulkCostCodeSelector
      count={selectedBuyoutItems.length}
      setLoading={setLoading}
      update={updateCostCode}
    />
  );
};
