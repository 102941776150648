import {
  FooterCell,
  FooterCellCol,
  FooterCol,
  FooterDashedLine,
  FooterHeader,
  FooterHeaderText,
  FooterItemInputContainer,
  FooterTotal,
} from "@/common/components/grid-table/styles/Footer.styles";
import { If } from "@/common/components/if/If";
import { usePermissions } from "@/common/components/org-roles-wrapper/hasPermissions";
import { Permission } from "@/common/components/org-roles-wrapper/OrgRolesWrapper";
import {
  AmountPercentageSwitch,
  SalesTaxType,
} from "@/common/components/sales-tax-input/components/use-sales-tax/toggle/AmountPercentageSwitch";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { useBuyoutGrouped } from "../../non-quoted/providers/BuyoutGroupedProvider";

type Props = {
  includeTax?: boolean;
  includeLabels?: boolean;
};

const TitleNormal = tw.div`text-right`;
const Title = tw.div`font-medium`;
const Container = tw.div`text-sm whitespace-nowrap`;

export const BuyoutTitleColFooter: FC<Props> = ({
  includeTax,
  includeLabels,
}) => {
  const { editSalesTax, isSaleTaxAmount, setIsSaleTaxAmount } =
    useBuyoutGrouped();
  const { hasPermissions: canViewPrices } = usePermissions([
    Permission.canViewPrices,
  ]);

  if (!canViewPrices) {
    return null;
  }

  return (
    <FooterCol className="min-w-[50px] flex-col items-end">
      <If isTrue={includeTax}>
        <FooterItemInputContainer>
          <If isTrue={includeLabels}>
            <FormattedMessage
              id="ADDITIONAL_CHARGES_ALLOWANCE"
              tagName={Container}
            />
          </If>
        </FooterItemInputContainer>
        <FooterItemInputContainer className={editSalesTax ? "w-64" : ""}>
          <If isTrue={includeLabels}>
            {!editSalesTax ? (
              <FormattedMessage id="SALES_TAX" tagName={Container} />
            ) : (
              <AmountPercentageSwitch
                type={
                  isSaleTaxAmount ? SalesTaxType.Amount : SalesTaxType.Percent
                }
                changeType={() => setIsSaleTaxAmount(!isSaleTaxAmount)}
              />
            )}
          </If>
        </FooterItemInputContainer>
      </If>
      <FooterHeader />
      <FooterCell>
        <FormattedMessage id="SUBTOTAL" tagName={TitleNormal} />:
      </FooterCell>
      <FooterCell>
        <TitleNormal>
          <FormattedMessage id="SALES_TAX" />:
        </TitleNormal>
      </FooterCell>
      <FooterDashedLine />
      <FooterTotal>
        <FooterCellCol className="items-end">
          <Title>
            <FormattedMessage id="TOTAL" />:
          </Title>
          <FormattedMessage
            id="INCL_TAXES_AND_FEES"
            tagName={FooterHeaderText}
          />
        </FooterCellCol>
      </FooterTotal>
    </FooterCol>
  );
};
