import { useOrderTypeOptions } from "@/common/components/order-type-picker/hooks/useOrderTypeOptions";
import { useTaxCodeSummaries } from "@/common/components/sales-tax-input/hooks/useTaxCodeSummaries";
import { getTotalTaxAmount } from "@/common/components/sales-tax-input/utils/salesTaxUtils";
import { UpdateChargeInput } from "@/generated/graphql";
import Decimal from "decimal.js";
import { useCallback } from "react";

type TotalProps = {
  customTaxAmount: string | undefined | null;
  taxRate: string | undefined;
  subtotal: number | string | undefined;
  taxCodeId: string | undefined;
  additionalCharges?: UpdateChargeInput[] | null;
  orderTypeId: string;
};

type OptionalDecimal = number | string | undefined | null;

export const extPriceCalculation = (
  quantity: OptionalDecimal,
  unitPrice: OptionalDecimal,
) => {
  if (
    quantity === undefined ||
    quantity === null ||
    quantity === "" ||
    unitPrice === undefined ||
    unitPrice === null ||
    unitPrice === "" ||
    isNaN(parseFloat(quantity.toString())) ||
    isNaN(parseFloat(unitPrice.toString()))
  ) {
    return 0;
  }
  const quant = new Decimal(parseFloat(quantity.toString()));
  const price = new Decimal(parseFloat(unitPrice.toString()));
  return quant.toDP(6).mul(price.toDP(6)).toDP(2).toNumber();
};

const unitPriceCalculation = (
  quantity: number | string | undefined | null,
  extPrice: number | string | undefined | null,
) => {
  if (
    quantity === undefined ||
    quantity === null ||
    quantity === "" ||
    isNaN(parseFloat(quantity.toString())) ||
    extPrice === undefined ||
    extPrice === null ||
    extPrice === "" ||
    isNaN(parseFloat(extPrice.toString()))
  ) {
    return 0;
  }

  const decimalQuantity = new Decimal(parseFloat(quantity.toString()));
  const decimalExtPrice = new Decimal(parseFloat(extPrice.toString()));
  const unitPrice = decimalQuantity.equals(0)
    ? new Decimal("0")
    : decimalExtPrice.div(decimalQuantity);
  let decimalPlaces = unitPrice.toString().split(".")[1]?.length;

  if (!decimalPlaces) {
    return unitPrice.toNumber();
  }

  while (
    decimalPlaces > 0 &&
    extPriceCalculation(
      decimalQuantity.toNumber(),
      unitPrice.toDP(decimalPlaces - 1).toNumber(),
    ) === decimalExtPrice.toNumber()
  ) {
    decimalPlaces--;
  }

  return unitPrice.toDP(decimalPlaces).toNumber();
};

const calcAdditionalChargesPrice = (
  additionalCharges: UpdateChargeInput[] | null | undefined,
) => {
  const charges =
    additionalCharges?.reduce(
      (acc, charge) => new Decimal(acc).add(charge.amount || 0),
      new Decimal(0),
    ) ?? new Decimal(0);
  return charges.toString();
};

export const usePriceCalculation = () => {
  const { taxCodes } = useTaxCodeSummaries();
  const { getOrderType } = useOrderTypeOptions();

  const calcTotalPrice = useCallback(
    ({
      subtotal,
      taxCodeId,
      customTaxAmount,
      taxRate,
      orderTypeId,
      additionalCharges,
    }: TotalProps) => {
      const subTotal = new Decimal(subtotal || 0);
      const taxCodeRate = taxCodes.find((tc) => tc.id === taxCodeId)?.rate;
      const additionalChargesTotal =
        calcAdditionalChargesPrice(additionalCharges);
      const totalTax = getTotalTaxAmount({
        customTaxAmount,
        taxRate,
        subtotal: subTotal.toString(),
        taxCode: taxCodeRate ? { rate: taxCodeRate } : undefined,
        type: getOrderType(orderTypeId),
        chargesAmount: additionalChargesTotal,
      });
      return subTotal.plus(totalTax).plus(additionalChargesTotal).toNumber();
    },
    [taxCodes, getOrderType],
  );

  return {
    calcExtPrice: useCallback(extPriceCalculation, []),
    calcUnitPrice: useCallback(unitPriceCalculation, []),
    calcTotalPrice,
    calcAdditionalChargesPrice,
  };
};
