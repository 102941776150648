import { If } from "@/common/components/if/If";
import { Instructions } from "@/common/components/instructions/Instructions";
import { Loader } from "@/common/components/loader/Loader";
import { QuoteDocumentPanel } from "@/common/components/quote-document-panel/QuoteDocumentPanel";
import { SpreadSheetTable } from "@/common/components/spreadsheet-table/SpreadSheetTable";
import { SAVED_LIST_VIEW_ID } from "@/common/const";
import {
  ColumnMapperProvider,
  useColumnMapper,
} from "@/common/providers/ColumnMapperProvider";
import { EstimatedPricesProvider } from "@/common/providers/EstimatedPricesProvider";
import {
  TableViewState,
  useTableViewStore,
} from "@/common/stores/useTableViewStore";
import { useVendorPrices } from "@/contractor/pages/admin/org-items/pages/materials-prices/hooks/useVendorPrices";
import {
  BuyoutFieldsFragment,
  BuyoutStatus,
  InstructionInput,
} from "@/generated/graphql";
import { FC, useCallback, useEffect, useState } from "react";
import tw from "tailwind-styled-components";
import { useShallow } from "zustand/react/shallow";
import { useSetCurrentProjectId } from "../../../project/hooks/useSetCurrentProjectId";
import { useContractorBuyout } from "../../providers/ContractorBuyoutProvider";
import { BuyoutStandaloneReleaseItemsList } from "../common/buyout-standalone-release-items-list/BuyoutStandaloneReleaseItemsList";
import { VendorBuyoutInlineNotesAndTermsPanel } from "../common/notes-and-attachments/VendorBuyoutInlineNotesAndTermsPanel";
import { useBuyoutItemsListConfiguration } from "../common/table-configurations/BuyoutListItemsConfiguration";
import { BuyoutActions } from "./components/BuyoutActions";
import { BuyoutAddItems } from "./components/BuyoutAddItems";
import { BuyoutDescription } from "./components/BuyoutDescription";
import { BuyoutList } from "./components/BuyoutList";
import { BuyoutNonQuotedHeader } from "./header/BuyoutNonQuotedHeader";
import {
  BuyoutGroupedProvider,
  useBuyoutGrouped,
} from "./providers/BuyoutGroupedProvider";
import {
  SyncBuyoutItemsProvider,
  useSyncBuyoutItems,
} from "./providers/SyncBuyoutItemsProvider";
const Container = tw.div`pb-15`;

const BuyoutNonQuotedWithProviders: FC<{
  buyout: BuyoutFieldsFragment;
  loading: boolean;
}> = ({ buyout, loading }) => {
  const { isEditable } = useBuyoutGrouped();
  const buyoutItemsListConfiguration = useBuyoutItemsListConfiguration();
  const viewState = useTableViewStore((state) => state.viewState);
  const { saving } = useSyncBuyoutItems();
  const { setGlobalVendorId } = useVendorPrices();
  const { spreadsheetData } = useColumnMapper();
  const [newSpreadsheetData, setNewSpreadsheetData] =
    useState<Record<string, string>[]>(spreadsheetData);
  const { updateBuyout, updating } = useContractorBuyout();

  useEffect(() => {
    if (setGlobalVendorId) {
      setGlobalVendorId(buyout?.sellerOrgLocation?.id);
    }
  }, [buyout?.sellerOrgLocation?.id, setGlobalVendorId]);

  const saveInstructions = useCallback(
    async (instructions: InstructionInput) => {
      if (!buyout) {
        return;
      }

      await updateBuyout({
        instructions,
      });
    },
    [buyout, updateBuyout],
  );

  const saveDescription = useCallback(
    (description: string) => {
      if (buyout) {
        updateBuyout(
          {
            buyoutId: buyout.id,
            description,
            version: buyout.version,
          },
          true,
        );
      }
    },
    [buyout, updateBuyout],
  );

  return (
    <Container>
      <BuyoutNonQuotedHeader buyout={buyout} />
      <If isTrue={viewState === TableViewState.normal}>
        <BuyoutList
          buyoutItems={buyout.items}
          loading={loading}
          totalCount={buyout.items.length}
          readonly={!isEditable}
        />
        <BuyoutStandaloneReleaseItemsList />
      </If>
      <If isTrue={viewState === TableViewState.spreadsheet}>
        <SpreadSheetTable
          items={buyout.items}
          columns={buyoutItemsListConfiguration.spreadsheetConfig}
          saving={saving}
          height="calc(100vh - 450px)"
          onChanges={setNewSpreadsheetData}
        />
      </If>
      <Instructions
        instruction={buyout.instructions}
        projectId={buyout.project?.id}
        saveInstruction={saveInstructions}
        readonly={!isEditable}
      >
        <BuyoutDescription
          buyout={buyout}
          saveDescription={saveDescription}
          loading={updating}
        />
      </Instructions>
      <VendorBuyoutInlineNotesAndTermsPanel />
      <QuoteDocumentPanel
        quoteDocument={buyout.quoteDocument}
        readonly={!isEditable}
      />
      <BuyoutActions spreadsheetData={newSpreadsheetData} />
      <If
        isTrue={
          buyout.status !== BuyoutStatus.Cancelled &&
          buyout.status !== BuyoutStatus.Active &&
          isEditable
        }
      >
        <BuyoutAddItems buyout={buyout} />
      </If>
    </Container>
  );
};

export const BuyoutNonQuoted = () => {
  const { buyout, loading } = useContractorBuyout();
  const buyoutItemsListConfiguration = useBuyoutItemsListConfiguration();
  useSetCurrentProjectId(buyout?.project?.id);
  const { initViewStore, resetViewStore } = useTableViewStore(
    useShallow((state) => ({
      initViewStore: state.initViewStore,
      resetViewStore: state.resetViewStore,
    })),
  );

  useEffect(() => {
    initViewStore(
      buyout?.status === BuyoutStatus.Draft
        ? TableViewState.spreadsheet
        : TableViewState.normal,
      buyout?.status === BuyoutStatus.Draft
        ? { id: SAVED_LIST_VIEW_ID.BUYOUT }
        : undefined,
    );
    return () => {
      resetViewStore();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initViewStore, resetViewStore]);

  if (!buyout) {
    return <Loader loading />;
  }

  return (
    <EstimatedPricesProvider projectId={buyout?.project?.id}>
      <ColumnMapperProvider
        config={buyoutItemsListConfiguration.spreadsheetConfig}
      >
        <BuyoutGroupedProvider buyout={buyout}>
          <SyncBuyoutItemsProvider>
            <BuyoutNonQuotedWithProviders buyout={buyout} loading={loading} />
          </SyncBuyoutItemsProvider>
        </BuyoutGroupedProvider>
      </ColumnMapperProvider>
    </EstimatedPricesProvider>
  );
};
