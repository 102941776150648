import { TaxType } from "@/generated/graphql";
import Decimal from "decimal.js";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { ItemContainer } from "../additional-charges/AdditionalCharges.styles";
import { If } from "../if/If";
import { useFormatNumberToCurrency } from "../value-currency/hooks/useFormatNumberToCurrency";
import { TaxExemptInfoTooltip } from "./components/common/TaxExemptInfoTooltip";
import { NativeSalesTaxInput } from "./components/use-native-sales-tax/NativeSalesTaxInput";
import { UseTaxSwitch } from "./components/use-native-sales-tax/toggle/UseTaxSwitch";
import {
  AmountPercentageSwitch,
  SalesTaxType,
} from "./components/use-sales-tax/toggle/AmountPercentageSwitch";
import { UseSalesTaxInput } from "./components/use-sales-tax/UseSalesTaxInput";
import { useTaxCalculation } from "./hooks/useTaxCalculation";
import { useTaxCodeSummaries } from "./hooks/useTaxCodeSummaries";
import { useTaxType } from "./hooks/useTaxType";
import { SalesTaxInputProps } from "./types/SalesTaxInputProps";

const Value = tw.div`font-normal text-sm`;
const Label = tw.div`flex gap-3 items-center justify-end`;
const SalesReadonlyTaxLabel = tw.div`font-medium text-sm`;

type OptionalReleaseSalesTaxProps = {
  canEdit: boolean;
  className?: string;
};

type Props = SalesTaxInputProps & OptionalReleaseSalesTaxProps;

export const ReleaseSalesTax: FC<Props> = ({
  onTypeChange,
  taxExempt,
  salesTaxInput,
  canEdit,
  updateRelease,
  className,
  fallbackOrderTypes,
  fallbackTaxCodes,
}) => {
  const [salesAmount, setSalesAmount] = useState(
    salesTaxInput.customTaxAmount ? Number(salesTaxInput.customTaxAmount) : 0,
  );
  const [type, setType] = useState<SalesTaxType>(
    salesTaxInput.customTaxAmount ? SalesTaxType.Amount : SalesTaxType.Percent,
  );
  const [taxType, setTaxType] = useState<TaxType | undefined>(
    salesTaxInput.taxType ?? undefined,
  );
  const [taxCode, setTaxCode] = useState<string | null>(
    salesTaxInput.taxCodeId ?? null,
  );
  const [salesTax, setSalesTax] = useState(
    salesTaxInput.taxRate
      ? Number(new Decimal(salesTaxInput.taxRate ?? 0).mul(100))
      : null,
  );
  const { formatCurrency } = useFormatNumberToCurrency();
  const { taxTypeKey, useTaxKey, isNativeSalesTax } = useTaxType(
    salesTaxInput.orderTypeId,
    fallbackOrderTypes,
    salesTaxInput.taxType,
  );

  const intl = useIntl();
  const { taxCodes: allTaxCodes } = useTaxCodeSummaries();
  const taxCodes = useMemo(
    () => (allTaxCodes.length ? allTaxCodes : fallbackTaxCodes || []),
    [allTaxCodes, fallbackTaxCodes],
  );

  const subtotalWithCharges = useMemo(
    () =>
      new Decimal(salesTaxInput.subtotal || 0).add(
        salesTaxInput.chargesAmount || 0,
      ),
    [salesTaxInput.subtotal, salesTaxInput.chargesAmount],
  );

  const calculatedTaxAmount = useMemo(() => {
    return subtotalWithCharges
      .mul(salesTax || 0)
      .div(100)
      .toNumber();
  }, [subtotalWithCharges, salesTax]);

  useEffect(() => {
    if (salesTaxInput.customTaxAmount) {
      setType(SalesTaxType.Amount);
    } else {
      setType(SalesTaxType.Percent);
    }
    setSalesAmount(
      salesTaxInput.customTaxAmount ? Number(salesTaxInput.customTaxAmount) : 0,
    );
    setSalesTax(
      salesTaxInput.taxRate
        ? Number(new Decimal(salesTaxInput.taxRate).mul(100))
        : 0,
    );
    setTaxType(
      salesTaxInput.taxType || (isNativeSalesTax ? TaxType.Use : undefined),
    );
    setTaxCode(salesTaxInput.taxCodeId || null);
  }, [isNativeSalesTax, salesTaxInput]);
  const { getTaxAmount } = useTaxCalculation();

  const formattedTaxAmount = useMemo(() => {
    let taxAmount: number | undefined | string = undefined;
    if (isNativeSalesTax) {
      taxAmount = getTaxAmount(
        salesTaxInput.taxRate,
        salesTaxInput.customTaxAmount,
        salesTaxInput.subtotal,
      );
    } else {
      taxAmount = salesTaxInput.customTaxAmount ?? calculatedTaxAmount;
    }
    return `${formatCurrency(taxAmount, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}`;
  }, [
    calculatedTaxAmount,
    formatCurrency,
    getTaxAmount,
    isNativeSalesTax,
    salesTaxInput.customTaxAmount,
    salesTaxInput.subtotal,
    salesTaxInput.taxRate,
  ]);

  const nativeTaxCodeRate = useMemo(() => {
    const code = taxCodes.find((code) => code.id === taxCode);
    if (!code) {
      return;
    }
    const amount = new Decimal(salesTaxInput.subtotal || 0)
      .add(salesTaxInput.chargesAmount || 0)
      .mul(code.rate || 0)
      .toNumber();

    return `${intl.formatNumber(new Decimal(code?.rate || "0").mul(100).toNumber())}% (${formatCurrency(amount, { maximumFractionDigits: 2, minimumFractionDigits: 2 })})`;
  }, [
    taxCodes,
    salesTaxInput.subtotal,
    salesTaxInput.chargesAmount,
    intl,
    formatCurrency,
    taxCode,
  ]);

  const formattedSalesTax = useMemo(() => {
    if (canEdit) {
      return salesTax;
    }

    if (taxCode) {
      return nativeTaxCodeRate;
    }

    if (salesTaxInput.customTaxAmount) {
      return formattedTaxAmount;
    }

    return `${intl.formatNumber(Number(salesTax))}% (${formattedTaxAmount})`;
  }, [
    canEdit,
    salesTax,
    salesTaxInput.customTaxAmount,
    formattedTaxAmount,
    taxCode,
    nativeTaxCodeRate,
    intl,
  ]);

  const changeType = useCallback(
    async (newType: SalesTaxType) => {
      if (type === newType) {
        return;
      }
      if (newType) {
        if (newType === SalesTaxType.Amount) {
          const newCustomSalesTax = new Decimal(
            calculatedTaxAmount || "0",
          ).toNumber();
          if (updateRelease) {
            await updateRelease?.({
              releaseId: salesTaxInput.id || "",
              version: salesTaxInput.version,
              customTaxAmount: newCustomSalesTax.toString(),
              clearCustomTaxAmount: false,
            });
          } else {
            setSalesAmount(newCustomSalesTax);
            setSalesTax(0);
          }
        } else {
          const newTaxRate =
            subtotalWithCharges && Number(subtotalWithCharges)
              ? new Decimal(salesAmount || "0")
                  .div(
                    subtotalWithCharges ? Number(subtotalWithCharges) || 1 : 1,
                  )
                  .toDP(4)
                  .toNumber()
              : 0;
          if (updateRelease) {
            await updateRelease({
              releaseId: salesTaxInput.id || "",
              version: salesTaxInput.version,
              clearCustomTaxAmount: true,
              customTaxAmount: null,
              taxRate: newTaxRate.toString(),
            });
          } else {
            setSalesTax(newTaxRate);
            setSalesAmount(0);
          }
        }
        onTypeChange?.(newType);
      }
    },
    [
      type,
      onTypeChange,
      calculatedTaxAmount,
      updateRelease,
      salesTaxInput.id,
      salesTaxInput.version,
      salesAmount,
      subtotalWithCharges,
    ],
  );

  const changeNativeTax = useCallback(
    async (newType: TaxType) => {
      if (newType) {
        if (updateRelease) {
          await updateRelease({
            releaseId: salesTaxInput.id || "",
            version: salesTaxInput.version,
            taxType: newType,
            clearCustomTaxAmount: undefined,
            taxRate: undefined,
            customTaxAmount: undefined,
          });
        } else {
          setTaxType(newType);
        }
      }
    },
    [updateRelease, salesTaxInput.id, salesTaxInput.version],
  );

  return (
    <>
      <ItemContainer className={className} data-testid="order-salesTax">
        <Label>
          <FormattedMessage
            id={!canEdit && isNativeSalesTax ? useTaxKey : taxTypeKey}
            tagName={!canEdit ? SalesReadonlyTaxLabel : undefined}
          />
          <If isTrue={canEdit}>
            <If isTrue={!isNativeSalesTax}>
              <AmountPercentageSwitch type={type} changeType={changeType} />
            </If>
            <If isTrue={isNativeSalesTax && allTaxCodes.length > 0}>
              <UseTaxSwitch changeType={changeNativeTax} type={taxType} />
            </If>
          </If>
        </Label>
        <If isTrue={!canEdit}>
          <Value>{formattedSalesTax}</Value>
        </If>
        <If isTrue={canEdit}>
          <If isTrue={!isNativeSalesTax}>
            <UseSalesTaxInput
              formattedTaxAmount={formattedTaxAmount}
              salesAmount={salesAmount}
              salesTax={salesTax}
              setSalesAmount={setSalesAmount}
              setSalesTax={setSalesTax}
              type={type}
              updateRelease={updateRelease}
              salesTaxInput={salesTaxInput}
            />
          </If>
          <If isTrue={isNativeSalesTax}>
            <NativeSalesTaxInput
              salesTaxInput={salesTaxInput}
              setTaxCode={setTaxCode}
              taxCode={taxCode}
              updateRelease={updateRelease}
              taxType={taxType}
              fallbackTaxCodes={fallbackTaxCodes}
            />
          </If>
        </If>
        <TaxExemptInfoTooltip taxExempt={taxExempt} />
      </ItemContainer>
      <If isTrue={isNativeSalesTax && nativeTaxCodeRate && canEdit}>
        <ItemContainer className={className} data-testid="order-salesTax">
          <Label>
            <FormattedMessage id="TAX" tagName={SalesReadonlyTaxLabel} />
          </Label>
          <Value>{nativeTaxCodeRate}</Value>
        </ItemContainer>
      </If>
    </>
  );
};
