export enum IntegrationFeature {
  // default steps
  PoExportSettingsStep = "PoExportSettingsStep",

  // custom steps
  GlobalPhaseCodesStep = "GlobalPhaseCodesStep",
  ApLedgerAccountStep = "ApLedgerAccountStep",

  // features
  GeneralLedgerAccount = "GeneralLedgerAccount",
  PostInventoryReceipts = "PostInventoryReceipts",
  PoType = "PoType",
  WarehouseSupport = "WarehouseSupport",
  IncludeCostCodes = "IncludeCostCodes",
  IncludeCostTypes = "IncludeCostTypes",
  IncludeEquipment = "IncludeEquipment",
  IncludeServiceCodes = "IncludeServiceCodes",
  IncludePaymentMethods = "IncludePaymentMethods",
  AutoPostPOs = "AutoPostPOs",
  AutoPostInvoices = "AutoPostInvoices",
  DisableExternalPoPagination = "DisableExternalPoPagination",
  DefaultItemOrderTypeMapping = "DefaultItemOrderTypeMapping",
  SupportPhasesOrExtrasAsZones = "SupportPhasesOrExtrasAsZones",
  GLAccountForCostCodes = "GLAccountForCostCodes",
  GLAccountForNonConnectedCostCodes = "GLAccountForNonConnectedCostCodes",
  ImportProjectCostCodes = "ImportProjectCostCodes",

  // mapping
  OrgMaterialsMapping = "OrgMaterialsMapping",

  //order types
  IncludeOrderTypeSalesTaxOrgMaterial = "IncludeOrderTypeSalesTaxOrgMaterial",
  IncludeOrderTypeBillable = "IncludeOrderTypeBillable",

  //tax codes
  IncludeTaxAuthorities = "IncludeTaxAuthorities",

  // synchronization
  POBatching = "POBatching",
  InvoiceBatching = "InvoiceBatching",
  InvoiceWrites = "InvoiceWrites",
  InvoiceAutoPost = "InvoiceAutoPost",
  ReceiptsFileBased = "ReceiptsFileBased",
  ExternalPoReadsProjectSpecific = "ExternalPoReadsProjectSpecific",
  ExternalPoReadsGlobal = "ExternalPoReadsGlobal",
  PoWrites = "PoWrites",
  ReversePoSync = "ReversePoSync",
  InvoiceFileExports = "InvoiceFileExports",
  OrderItemPOItemReferences = "OrderItemPOItemReferences",
  POAlwaysMutable = "POAlwaysMutable",
}
