import { InlineNotesAndTerms } from "@/common/components/inline-notes-and-terms/InlineNotesAndTerms";
import { UploadAssetProvider } from "@/common/components/upload-asset/UploadAssetProvider";
import { AssetContext } from "@/generated/graphql";
import { FC } from "react";
import { useIntl } from "react-intl";
import { useContractorBuyout } from "../../../providers/ContractorBuyoutProvider";

const VendorBuyoutInlineNotesAndTermsWithProvider: FC = () => {
  const { buyout } = useContractorBuyout();
  const intl = useIntl();

  if (!buyout || (!buyout.notes && !buyout.assets.length)) {
    return null;
  }

  return (
    <InlineNotesAndTerms
      notes={buyout.notes}
      readonly
      header={intl.$t({ id: "VENDOR_NOTES_AND_TERMS" })}
    />
  );
};

export const VendorBuyoutInlineNotesAndTermsPanel: FC = () => {
  const { buyout } = useContractorBuyout();
  return (
    <UploadAssetProvider
      initialAssets={buyout?.assets}
      context={AssetContext.Note}
      projectId={buyout?.project?.id}
    >
      <VendorBuyoutInlineNotesAndTermsWithProvider />
    </UploadAssetProvider>
  );
};
