import { ExternalPoBaseFieldsFragment } from "@/generated/graphql";
import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { ExternalPoImportVariables } from "../types/ExternalPoImportVariables";
import { ImportableItem } from "../types/ImportableItem";

type ExternalPoImportOptions = {
  forceFetchExternalPO?: boolean;
  readonlyMode?: boolean;
  includeDate?: boolean;
  labelKeys?: {
    importButton?: string;
  };
  disableImportButton?: boolean;
};

type State = {
  selectedBaseExternalPO: ExternalPoBaseFieldsFragment | undefined;
  setSelectedBaseExternalPO: (
    baseExternalPO: ExternalPoBaseFieldsFragment | undefined,
  ) => void;
  showModal: boolean;
  setShowModal: (showModal: boolean) => void;
  variables: ExternalPoImportVariables;
  setVariables: (variables: Partial<ExternalPoImportVariables>) => void;
  options: ExternalPoImportOptions;
  setOptions: (options: Partial<ExternalPoImportOptions>) => void;
  reset: () => void;
  importedItems: ImportableItem[] | null;
  setImportedItems: (items: ImportableItem[] | null) => void;
};

export const useImportExternalPoStore = create<State>()(
  devtools((set, get) => ({
    selectedBaseExternalPO: undefined,
    setSelectedBaseExternalPO: (
      baseExternalPO: ExternalPoBaseFieldsFragment | undefined,
    ) =>
      set(() => ({
        selectedBaseExternalPO: baseExternalPO,
      })),
    showModal: false,
    setShowModal: (showModal: boolean) => {
      set(() => ({
        showModal,
      }));
      if (!showModal) {
        get().reset();
      }
    },
    variables: {
      projectId: null,
      orderTypeId: null,
      poNumber: null,
      release: null,
      sellerOrgLocationId: null,
      importedDescription: null,
      importedPoIsImmutable: false,
      importedTaxAmount: "",
      importedTaxCodeId: null,
      importedTaxType: null,
      importedPoItemized: null,
      importedOrderTypeId: null,
      importedLocationId: null,
      importedProjectId: null,
      importedPoExternalId: null,
      importedSellerOrgLocationId: null,
      importedAdditionalCharges: undefined,
      importedCustomTaxAmount: undefined,
      importedPoDate: null,
      importedPoNumber: null,
      date: null,
    },
    setVariables: (variables: Partial<ExternalPoImportVariables>) =>
      set((state) => ({
        variables: {
          ...state.variables,
          ...variables,
        },
      })),
    options: {},
    setOptions: (options: Partial<ExternalPoImportOptions>) =>
      set((state) => ({
        options: {
          ...state.options,
          ...options,
        },
      })),
    importedItems: [],
    setImportedItems: (items: ImportableItem[] | null) =>
      set(() => ({
        importedItems: items,
      })),
    reset: () =>
      set((state) => ({
        selectedBaseExternalPO: undefined,
        showModal: false,
        variables: {
          ...state.variables,
          projectId: null,
          orderTypeId: null,
          poNumber: null,
          release: null,
          sellerOrgLocationId: null,
          date: null,
        },
        options: {
          forceFetchExternalPO: false,
          readonlyMode: false,
          disableImportButton: false,
        },
      })),
  })),
);
