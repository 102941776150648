import { UpdateReleaseWithAdditionalChargesAndTaxes } from "@/common/components/release-additional-charges-and-taxes/ReleaseAdditionalChargesAndTaxesTypes";
import { TaxCodeSummaryFieldsFragment, TaxType } from "@/generated/graphql";
import { FC, useCallback } from "react";
import { SalesTaxInput } from "../../types/SalesTaxInputType";
import { NativeSalesTaxPicker } from "./input/NativeSalesTaxPicker";

type Props = {
  taxCode: string | null;
  taxType: TaxType | undefined;
  setTaxCode: (value: string | null) => void;
  updateRelease?: UpdateReleaseWithAdditionalChargesAndTaxes;
  salesTaxInput: SalesTaxInput;
  fallbackTaxCodes?: TaxCodeSummaryFieldsFragment[];
};

export const NativeSalesTaxInput: FC<Props> = ({
  taxCode,
  taxType,
  setTaxCode,
  updateRelease,
  salesTaxInput,
  fallbackTaxCodes,
}) => {
  const onChange = useCallback(
    async (taxCodeId: string | null) => {
      if (updateRelease) {
        await updateRelease({
          releaseId: salesTaxInput.id || "",
          version: salesTaxInput.version,
          taxCodeId,
          taxType,
        });
      } else {
        setTaxCode(taxCode);
      }
    },
    [
      salesTaxInput.id,
      salesTaxInput.version,
      setTaxCode,
      taxCode,
      taxType,
      updateRelease,
    ],
  );

  return (
    <NativeSalesTaxPicker
      onChange={onChange}
      value={taxCode}
      fallbackTaxCodes={fallbackTaxCodes}
    />
  );
};
