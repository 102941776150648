import { BulkPhaseCodeSelector } from "@/common/components/cost-code-selector/BulkPhaseCodeSelector";
import { BuyoutStatus } from "@/generated/graphql";
import { FC, useCallback } from "react";
import { useContractorBuyout } from "../../../providers/ContractorBuyoutProvider";

type Props = {
  setLoading: (loading: boolean) => void;
};

export const BuyoutBulkPhaseCodeSelector: FC<Props> = ({ setLoading }) => {
  const { buyout, selectedBuyoutItems, setSelectedBuyoutItems, updateBuyout } =
    useContractorBuyout();

  const updatePhaseCode = useCallback(
    async (code: string | null) => {
      if (!buyout) {
        return;
      }
      await updateBuyout(
        {
          updates: selectedBuyoutItems.map((id) => ({
            buyoutItemId: id,
            tags: code ? [code] : [],
          })),
        },
        buyout?.status === BuyoutStatus.Active,
      );
      setSelectedBuyoutItems([]);
    },
    [buyout, selectedBuyoutItems, setSelectedBuyoutItems, updateBuyout],
  );

  return (
    <BulkPhaseCodeSelector
      count={selectedBuyoutItems.length}
      setLoading={setLoading}
      update={updatePhaseCode}
    />
  );
};
