import { PrimaryButton } from "@/common/components/button/PrimaryButton";
import { ButtonsContainer } from "@/common/components/list-buttons-container/ButtonsContainer";
import { Tabs } from "@/common/components/tabs/Tabs";
import { useUser } from "@/common/providers/UserProvider";
import { routes } from "@/config/routes";
import { ToggleRfqForm } from "@/contractor/pages/home/rfq-master-catalog-import/components/ToggleRfqForm";
import {
  AuthorizationStatus,
  OrgLocationRole,
  OrgRole,
  ProjectStatus,
} from "@/generated/graphql";
import { useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { generatePath, useParams } from "react-router-dom";
import tw from "tailwind-styled-components";

import { ToggleBuyoutForm } from "../../buyouts/components/new-buyout/ToggleBuyoutsForm";
import { NewDeliveryButton } from "../../releases/pages/deliveries/components/new-delivery/NewDeliveryButton";
import { useProject } from "../providers/ProjectProvider";

const ButtonsContainerStyled = tw(ButtonsContainer)`pb-1`;

export const ProjectNavigation = () => {
  const intl = useIntl();
  const { id } = useParams();
  const { viewer } = useUser();
  const { project } = useProject();

  const isOrgRestrictedMember = useMemo(
    () => viewer?.orgRoles.includes(OrgRole.OrgRestrictedMember),
    [viewer],
  );

  const isProjectArchived = useMemo(
    () => !!project?.deletedAt,
    [project?.deletedAt],
  );

  const canSeeReport = useMemo(() => {
    return project?.permissions.report === AuthorizationStatus.Authorized;
  }, [project?.permissions.report]);

  const isProjectClosed = useMemo(
    () =>
      !!project?.status &&
      [ProjectStatus.Completed, ProjectStatus.Lost].includes(project.status),
    [project?.status],
  );

  const isProjectForeman = useMemo(() => {
    const viewerRolesInLocation =
      viewer?.locationRoles.filter(
        (el) => el.orgLocID === project?.location.id,
      ) || [];
    return viewerRolesInLocation
      .map((item) => item.role)
      .includes(OrgLocationRole.LocationForeman);
  }, [project?.location.id, viewer?.locationRoles]);

  const tabs = useMemo(
    () => [
      {
        label: intl.$t({ id: "PROJECT_DETAILS" }),
        path: generatePath(routes.projectDetails, { id }),
      },
      {
        label: intl.$t({ id: "PROJECT_ZONES" }),
        path: generatePath(routes.projectZones, { id }),
      },
      {
        label: intl.$t({ id: "PROJECT_TAGS" }),
        path: generatePath(routes.projectTags, { id }),
      },
      {
        label: intl.$t({ id: "ESTIMATED_ITEMS" }),
        path: generatePath(routes.projectEstimatedItems, { id }),
      },
      {
        label: intl.$t({ id: "PROJECT_BUDGET" }),
        path: generatePath(routes.projectBudget, { id }),
        excluded: isOrgRestrictedMember || isProjectForeman,
      },
      {
        label: intl.$t({ id: "PROJECT_BUDGET_REPORT" }),
        path: generatePath(routes.projectBudgetReport, { id }),
        excluded: !canSeeReport,
      },
    ],
    [canSeeReport, id, intl, isOrgRestrictedMember, isProjectForeman],
  );

  return (
    <Tabs
      tabs={tabs}
      actions={
        isProjectClosed ? null : (
          <ButtonsContainerStyled>
            <ToggleRfqForm
              label={intl.$t({ id: "CREATE_RFQ_DIALOG_REQUEST_FOR_QUOTE" })}
              project={project}
              disabled={!!isProjectArchived}
            >
              <PrimaryButton
                disabled={!!project?.deletedAt}
                testId="new-rfq-button"
                $small
              >
                <FormattedMessage id="NEW_RFQ" />
              </PrimaryButton>
            </ToggleRfqForm>
            <ToggleBuyoutForm
              label={intl.$t({ id: "NEW_BUYOUT" })}
              project={project}
              disabled={!!isProjectArchived}
            >
              <PrimaryButton $small disabled={!!project?.deletedAt}>
                <FormattedMessage id="NEW_BUYOUT" />
              </PrimaryButton>
            </ToggleBuyoutForm>
            <NewDeliveryButton
              small
              project={project}
              disabled={!!isProjectArchived}
            />
          </ButtonsContainerStyled>
        )
      }
    />
  );
};
