import { ReleaseAdditionalChargesAndTaxes } from "@/common/components/release-additional-charges-and-taxes/ReleaseAdditionalChargesAndTaxes";
import { useTaxCalculation } from "@/common/components/sales-tax-input/hooks/useTaxCalculation";
import { useTableHelpers } from "@/common/components/spreadsheet-table/hooks/useTableHelpers";
import { SpreadSheetTable } from "@/common/components/spreadsheet-table/SpreadSheetTable";
import { useColumnMapper } from "@/common/providers/ColumnMapperProvider";
import { useProjectStore } from "@/contractor/pages/home/project/store/useProjectStore";
import { usePriceCalculation } from "@/contractor/pages/home/release/hooks/usePriceCalculation";
import {
  AdditionalChargesFieldsFragment,
  UpdateContractorReleaseInput,
} from "@/generated/graphql";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import tw from "tailwind-styled-components";
import {
  DeliverySlipMappedItem,
  useDeliverySlipImportExternalPO,
} from "../../../../providers/DeliverySlipImportExternalPOProvider";
import { useDeliverySlipRelease } from "../../../../providers/DeliverySlipReleaseProvider";
import { useDeliverySlipVerification } from "../../../../providers/DeliverySlipVerificationProvider";
import { DeliverySlipReleaseFormValues } from "../delivery-slip-form/DeliverySlipVerificationForm";
import { useDeliverySlipReleaseSpreadsheetConfig } from "./DeliverySlipRelease.config";
import { DeliverySlipCreateReleaseHeader } from "./DeliverySlipReleaseHeader";
import { DeliverySlipReleasePhotosPanel } from "./DeliverySlipReleasePhotosPanel";

const Container = tw.div`w-full flex flex-col flex-1 items-start px-2.5`;
const SpreadSheetView = tw.div`w-full mt-5 drop-shadow-md rounded-2xl h-fit`;
const Footer = tw.div`mr-7 mt-10 w-full pb-5`;

export const DeliverySlipRelease: FC = () => {
  const { deliverySlip } = useDeliverySlipVerification();
  const { loading, setDeliveryPhotos } = useDeliverySlipRelease();
  const { importedItems, importedTaxCodeId, importedPoIsImmutable } =
    useDeliverySlipImportExternalPO();
  const { findMaterialByName } = useTableHelpers();
  const { getPrefilledValue, calcTableTotal } = useTableHelpers();
  const { spreadsheetData } = useColumnMapper();
  const spreadsheetViewColumns = useDeliverySlipReleaseSpreadsheetConfig();
  const { getTaxAmount } = useTaxCalculation();
  const setGlobalProjectId = useProjectStore(
    (state) => state.setCurrentProjectId,
  );

  const { watch, setValue } = useFormContext<DeliverySlipReleaseFormValues>();

  const taxRate = watch("taxRate");
  const customTaxAmount = watch("customTaxAmount");
  const subtotal = watch("subtotal");
  const additionalCharges = watch("additionalCharges");
  const orderTypeId = watch("orderTypeId");
  const taxCodeId = watch("taxCodeId");
  const taxType = watch("taxType");
  const taxAmount = getTaxAmount(taxRate, customTaxAmount, subtotal);
  const projectId = watch("projectId");

  useEffect(() => {
    if (projectId) {
      setGlobalProjectId(projectId);
    }
  }, [setGlobalProjectId, projectId]);

  const updateSubtotal = useCallback(
    (data: Record<string, string>[]) => {
      const newTotal = calcTableTotal(data);
      if (newTotal !== subtotal) {
        setValue("subtotal", newTotal);
      }
    },
    [calcTableTotal, setValue, subtotal],
  );

  useEffect(() => {
    updateSubtotal(spreadsheetData);
  }, [spreadsheetData, updateSubtotal]);

  const { calcTotalPrice, calcAdditionalChargesPrice } = usePriceCalculation();

  const total = useMemo(
    () =>
      calcTotalPrice({
        subtotal,
        customTaxAmount,
        taxCodeId,
        taxRate,
        orderTypeId,
        additionalCharges,
      }),
    [
      calcTotalPrice,
      subtotal,
      customTaxAmount,
      taxCodeId,
      taxRate,
      orderTypeId,
      additionalCharges,
    ],
  );

  const [tableItems, setTableItems] = useState<DeliverySlipMappedItem[]>([]);

  useEffect(() => {
    if (tableItems.length === 0) {
      setTableItems(
        importedItems ??
          deliverySlip?.items.map((item) => {
            const matchingOrgMaterial = findMaterialByName(
              item.description ?? "",
            );
            const prefilledPrice = getPrefilledValue({
              material: item.description ?? "",
              manufacturer:
                matchingOrgMaterial?.manufacturer?.name ??
                item.manufacturer ??
                "",
              uom:
                matchingOrgMaterial?.defaultEstimateUom?.pluralDescription ??
                matchingOrgMaterial?.defaultEstimateUom?.mnemonic ??
                item.uom ??
                "",
            });

            return {
              ...item,
              quantityDecimal: item.orderedQuantity,
              receivedQuantityDecimal: item.receivedQuantity ?? "0",
              material: matchingOrgMaterial,
              costCode: matchingOrgMaterial?.costCode?.code ?? undefined,
              manufacturer:
                item.manufacturer ?? matchingOrgMaterial?.manufacturer?.name,
              UOM:
                item.uom ??
                matchingOrgMaterial?.defaultEstimateUom?.pluralDescription ??
                matchingOrgMaterial?.defaultEstimateUom?.mnemonic,
              unitPrice: prefilledPrice?.value ?? undefined,
            };
          }) ??
          [],
      );
    } else if (importedItems) {
      setTableItems(importedItems);
    }
  }, [
    importedItems,
    deliverySlip?.items,
    findMaterialByName,
    tableItems.length,
    getPrefilledValue,
  ]);

  const handleUpdateRelease = useCallback(
    (values: UpdateContractorReleaseInput) => {
      if (values.additionalCharges) {
        setValue("additionalCharges", values.additionalCharges);
      }
      if (
        values.customTaxAmount ||
        (values.clearCustomTaxAmount === false && customTaxAmount === undefined)
      ) {
        setValue("customTaxAmount", values.customTaxAmount || "0");
        setValue("taxRate", undefined);
      }
      if (values.taxRate || values.clearCustomTaxAmount) {
        setValue("customTaxAmount", undefined);
        setValue("taxRate", values.taxRate || "0");
      }
      if (values.taxCodeId) {
        setValue("taxCodeId", values.taxCodeId);
      }
      if (values.taxType) {
        setValue("taxType", values.taxType);
      }
      if (values.paymentTerm || values.paymentTerm === 0) {
        setValue("paymentTerm", values.paymentTerm);
      }

      return true;
    },
    [customTaxAmount, setValue],
  );

  useEffect(() => {
    if (importedTaxCodeId) {
      setValue("taxCodeId", importedTaxCodeId);
    }
  }, [importedTaxCodeId, setValue]);

  const releaseInput = useMemo(
    () => ({
      customTaxAmount,
      taxRate,
      orderTypeId,
      taxAmount,
      subtotal,
      taxCodeId,
      taxType,
      chargesAmount: calcAdditionalChargesPrice(additionalCharges),
    }),
    [
      additionalCharges,
      calcAdditionalChargesPrice,
      customTaxAmount,
      orderTypeId,
      subtotal,
      taxAmount,
      taxCodeId,
      taxRate,
      taxType,
    ],
  );

  return (
    <Container>
      <DeliverySlipCreateReleaseHeader />
      <SpreadSheetView>
        <SpreadSheetTable
          items={tableItems}
          columns={spreadsheetViewColumns}
          saving={loading}
          height="350px"
          rowNumber={17}
          onChanges={updateSubtotal}
          readOnly={importedPoIsImmutable}
        />
      </SpreadSheetView>
      <DeliverySlipReleasePhotosPanel
        updateAssets={(assets) => setDeliveryPhotos(assets)}
      />
      <Footer>
        <ReleaseAdditionalChargesAndTaxes
          total={`${total}`}
          releaseInput={releaseInput}
          editableByContractor={!importedPoIsImmutable}
          updateRelease={(values) =>
            handleUpdateRelease({ ...values, version: -1 })
          }
          includePaymentTerms
          editablePaymentTerms
          additionalCharges={
            watch("additionalCharges") as AdditionalChargesFieldsFragment[]
          }
        />
      </Footer>
    </Container>
  );
};
