import { useTableHelpers } from "@/common/components/spreadsheet-table/hooks/useTableHelpers";
import { useTableValidators } from "@/common/components/spreadsheet-table/hooks/useTableValidators";
import { useGlobalError } from "@/common/hooks/useGlobalError";
import {
  COLUMN_TYPE,
  SpreadSheetConfig,
  useColumnMapper,
} from "@/common/providers/ColumnMapperProvider";
import {
  AssetFieldsFragment,
  DeliverySlipDocument,
  DeliverySlipsDocument,
  namedOperations,
  ReleaseStatus,
  TaxType,
  UomsDocument,
  UpdateChargeInput,
  useCreateStandaloneReleaseMutation,
  useSubmitReleaseMutation,
  useUpdateContractorReleaseMutation,
} from "@/generated/graphql";
import {
  NoFunction,
  NoFunctionBooleanPromise,
  NoFunctionStringPromise,
} from "@/types/NoFunction";
import {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import { RELEASE_SUBMIT_ALLOWED_STATUSES } from "@/common/const";
import { useSnackbar } from "@/common/providers/SnackbarProvider";
import { RELEASE } from "@/common/queries/release";
import {
  TableViewState,
  useTableViewStore,
} from "@/common/stores/useTableViewStore";
import { cleanQuery } from "@/common/utils/cacheUtils";
import { useVendorPricesStore } from "@/contractor/pages/admin/org-items/pages/materials-prices/stores/useVendorPricesStore";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { useSyncReleaseItems } from "@/contractor/pages/home/release/pages/specify-details/hooks/useSyncReleaseItems";
import { useRelease } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import Decimal from "decimal.js";
import { useForm, UseFormReturn } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { useShallow } from "zustand/react/shallow";
import { useDeliverySlipReleaseSpreadsheetConfig } from "../components/delivery-slip-verification/components/delivery-slip-edit-release/DeliverySlipRelease.config";
import { DeliverySlipReleaseFormValues } from "../components/delivery-slip-verification/components/delivery-slip-form/DeliverySlipVerificationForm";
import { useDeliverySlipImportExternalPO } from "./DeliverySlipImportExternalPOProvider";
import { useDeliverySlipVerification } from "./DeliverySlipVerificationProvider";

type ReceivedQuantity = {
  releaseItemId: string;
  receivedQuantityDecimal: string | undefined | null;
};

export type UpdateReleaseFormValues = {
  businessLocationId: string;
  projectId: string;
  vendorId: string;
  poNumber: string;
  orderDate: Date;
  orderTypeId: string;
  subtotal: number;
  paymentTerm: string | undefined;
  additionalCharges: UpdateChargeInput[];
  taxRate: string | undefined;
  customTaxAmount: string | undefined;
  total: string;
  taxType: TaxType | undefined;
  taxCodeId: string | undefined;
};

type UpdateReleaseForm = UseFormReturn<UpdateReleaseFormValues, unknown>;

type ProviderContextType = {
  syncCreateReleaseFromDeliverySlip: (
    values: DeliverySlipReleaseFormValues,
  ) => Promise<string | boolean>;
  syncUpdateReleaseFromDeliverySlip: (
    values: UpdateReleaseFormValues,
  ) => Promise<boolean>;
  updateReleaseDeliverySlips: (deliverySlipUrls: string[]) => Promise<boolean>;
  loading: boolean;
  updating: boolean;
  spreadsheetViewColumns: SpreadSheetConfig[];
  setDeliveryPhotos: (assets: AssetFieldsFragment[]) => void;
  receivedQuantities: ReceivedQuantity[];
  updateReceivedItemQuantity: (id: string, quantityDecimal: string) => void;
  updateReleaseForm: UpdateReleaseForm;
  requestedTime: number | null;
  setRequestedTime: (time: number | null) => void;
};

const ProviderContext = createContext<ProviderContextType>({
  syncCreateReleaseFromDeliverySlip: NoFunctionStringPromise,
  syncUpdateReleaseFromDeliverySlip: NoFunctionBooleanPromise,
  updateReleaseDeliverySlips: NoFunctionBooleanPromise,
  loading: false,
  updating: false,
  spreadsheetViewColumns: [],
  setDeliveryPhotos: NoFunction,
  receivedQuantities: [],
  updateReceivedItemQuantity: NoFunction,
  updateReleaseForm: {} as UpdateReleaseForm,
  requestedTime: null,
  setRequestedTime: NoFunction,
});

export const DeliverySlipReleaseProvider: FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { setError } = useGlobalError();
  const { release } = useRelease();
  const { getSyncedRelease } = useSyncReleaseItems();
  const { deliverySlip, refetch } = useDeliverySlipVerification();
  const {
    importedPoExternalId,
    importedPoItemized,
    importedItems,
    importedDescription,
  } = useDeliverySlipImportExternalPO();
  const { validateRequiredValues, validateRowValues } = useTableValidators();
  const [receivedQuantities, setReceivedQuantities] = useState<
    ReceivedQuantity[]
  >([]);
  const [requestedTime, setRequestedTime] = useState<number | null>(null);

  const [deliveryPhotos, setDeliveryPhotos] = useState<AssetFieldsFragment[]>(
    [],
  );
  const [submitReleaseMutation] = useSubmitReleaseMutation({
    update: (cache) => cleanQuery(cache, namedOperations.Query.Releases),
  });
  const [saving, setSaving] = useState(false);
  const { initViewStore, resetViewStore } = useTableViewStore(
    useShallow((state) => ({
      initViewStore: state.initViewStore,
      resetViewStore: state.resetViewStore,
    })),
  );
  const { fetchVendorPrices } = useVendorPricesStore(
    useShallow((state) => ({
      fetchVendorPrices: state.fetchVendorPrices,
    })),
  );

  const updateReleaseForm: UpdateReleaseForm = useForm<UpdateReleaseFormValues>(
    {
      defaultValues: {
        businessLocationId: release?.project?.location?.id || "",
        projectId: release?.project?.id,
        vendorId: release?.sellerOrgLocation?.id ?? "",
        poNumber: release?.poNumber ?? "",
        orderDate: release?.time
          ? new Date(release?.time)
          : deliverySlip?.fulfillmentDate
            ? new Date(deliverySlip?.fulfillmentDate)
            : undefined,
        orderTypeId: release?.type.id,
        customTaxAmount: release?.taxAmount || undefined,
        taxRate: release?.taxRate || undefined,
        additionalCharges: release?.additionalCharges ?? [],
        subtotal: Number(release?.subtotal ?? 0),
        total: release?.total || "",
        paymentTerm:
          release?.paymentTerm || release?.paymentTerm === 0
            ? release.paymentTerm.toString()
            : "30",
      },
      mode: "onChange",
      reValidateMode: "onChange",
    },
  );

  useEffect(() => {
    initViewStore(TableViewState.spreadsheet);
    return () => {
      resetViewStore();
    };
  }, [initViewStore, resetViewStore]);

  useEffect(() => {
    if (release) {
      updateReleaseForm.reset({
        ...updateReleaseForm.getValues(),
        projectId: release?.project?.id,
        businessLocationId: release?.project?.location?.id,
        vendorId: release?.sellerOrgLocation?.id,
        taxRate: release?.taxRate || "",
        customTaxAmount: release?.taxAmount || "",
        subtotal: Number(release?.subtotal ?? 0),
        total: release?.total || "",
        paymentTerm:
          release?.paymentTerm || release?.paymentTerm === 0
            ? release.paymentTerm.toString()
            : "30",
      });
    }
  }, [release, updateReleaseForm]);

  const { spreadsheetData, resetPreviousData, gotoInvalidRow } =
    useColumnMapper();
  const { rowIsEmpty } = useTableHelpers();
  const { setWarningAlert } = useSnackbar();

  const { connectedSourceSystem, hasPhaseCodes: hasOrgSettingsPhaseCodes } =
    useOrgSettings();
  const { phaseCodeOptions } = useColumnMapper();

  const hasPhaseCodes = useMemo(
    () => hasOrgSettingsPhaseCodes && phaseCodeOptions?.length > 0,
    [hasOrgSettingsPhaseCodes, phaseCodeOptions?.length],
  );

  const [createStandaloneRelease, { loading: creatingRelease }] =
    useCreateStandaloneReleaseMutation();
  const syncCreateReleaseFromDeliverySlip = async (
    values: DeliverySlipReleaseFormValues,
  ) => {
    if (spreadsheetData.every((row) => rowIsEmpty(row))) {
      setWarningAlert(
        <FormattedMessage id={`VALIDATION_ERROR_SHEETS_EMPTY_LIST`} />,
      );
      return false;
    }

    if (
      !validateRequiredValues([
        COLUMN_TYPE.Material,
        COLUMN_TYPE.UOM,
        COLUMN_TYPE.Quantity,
      ]) ||
      !validateRowValues([
        COLUMN_TYPE.Quantity,
        COLUMN_TYPE.UOM,
        ...(hasPhaseCodes ? [COLUMN_TYPE.PhaseCode] : [COLUMN_TYPE.CostCode]),
      ])
    ) {
      gotoInvalidRow();
      return false;
    }

    setSaving(true);
    const { addedItems } = await getSyncedRelease({
      importedItems: importedItems ?? [],
      addItemsProps: {
        deliverySlipId: deliverySlip?.id,
      },
    });
    setSaving(false);

    if (addedItems.length > 0) {
      try {
        const { data, errors } = await createStandaloneRelease({
          variables: {
            input: {
              deliverySlipId: deliverySlip?.id ?? "",
              projectId: values.projectId ?? "",
              sellerOrgLocationId: values.vendorId,
              poNumber: values.poNumber,
              time: values.orderDate?.getTime() ?? undefined,
              items: addedItems,
              retroactive: true,
              deliveryPhotoUrls: deliveryPhotos.map((p) => p.url),
              mapping:
                importedPoExternalId && connectedSourceSystem
                  ? {
                      externalId: importedPoExternalId,
                      sourceSystem: connectedSourceSystem,
                    }
                  : undefined,
              typeId: values.orderTypeId || undefined,
              paymentTerm: values.paymentTerm,
              additionalCharges: values.additionalCharges,
              customTaxAmount: values.customTaxAmount || undefined,
              taxRate: values.taxRate || undefined,
              assignPOItemZones:
                !!importedPoExternalId && importedPoItemized === true
                  ? true
                  : undefined,
              taxCodeId: values.taxCodeId,
              taxType: values.taxType,
              description:
                importedPoExternalId && importedDescription
                  ? importedDescription
                  : null,
            },
          },
          awaitRefetchQueries: true,
          refetchQueries: [
            {
              query: UomsDocument,
            },
            {
              query: DeliverySlipsDocument,
            },
          ],
        });
        setError(errors);
        if (!errors) {
          fetchVendorPrices(true);
          resetPreviousData();
          refetch();
        }
        return data?.createStandaloneRelease?.id ?? "";
      } catch (error) {
        setError(error);
        return false;
      }
    }

    return true;
  };

  const [updateRelease, { loading: updating }] =
    useUpdateContractorReleaseMutation();
  const syncUpdateReleaseFromDeliverySlip = async (
    values: UpdateReleaseFormValues,
  ) => {
    if (
      !validateRequiredValues([
        COLUMN_TYPE.Material,
        COLUMN_TYPE.UOM,
        COLUMN_TYPE.Quantity,
      ]) ||
      !validateRowValues(
        [
          COLUMN_TYPE.Quantity,
          COLUMN_TYPE.UOM,
          ...(hasPhaseCodes ? [COLUMN_TYPE.PhaseCode] : [COLUMN_TYPE.CostCode]),
        ],
        undefined,
        { minPrice: undefined },
      )
    ) {
      gotoInvalidRow();
      return false;
    }

    setSaving(true);
    const { addedItems, removedItems, updates } = await getSyncedRelease();
    setSaving(false);

    if (release) {
      try {
        const { data, errors } = await updateRelease({
          variables: {
            input: {
              poNumber: values.poNumber,
              sellerOrgLocationId:
                release.status === ReleaseStatus.Draft ||
                release.status === ReleaseStatus.Reserved
                  ? values.vendorId
                  : undefined,
              requestedTime: values.orderDate?.getTime() ?? undefined,
              releaseId: release.id,
              version: release.version,
              addedItems,
              updates,
              removedItems,
              deliveryPhotoUrls: deliveryPhotos.map((p) => p.url),
              taxRate: values.taxRate === "" ? undefined : values.taxRate,
              customTaxAmount: values.customTaxAmount || undefined,
              clearCustomTaxAmount: !values.customTaxAmount,
              assignDefaultCostCodes: false,
              prefillPrices: false,
              paymentTerm:
                !!values?.paymentTerm || values?.paymentTerm === "0"
                  ? Number(values.paymentTerm)
                  : release.paymentTerm,
            },
          },
          awaitRefetchQueries: true,
          refetchQueries: [
            {
              query: RELEASE,
              variables: { id: release?.id ?? "" },
            },
            {
              query: UomsDocument,
            },
            {
              query: DeliverySlipDocument,
              variables: { id: deliverySlip?.id ?? "" },
            },
          ],
        });
        setError(errors);

        if (
          data?.updateContractorRelease.status &&
          data.updateContractorRelease.items.length > 0 &&
          RELEASE_SUBMIT_ALLOWED_STATUSES.includes(
            data?.updateContractorRelease.status,
          )
        ) {
          await submitReleaseMutation({
            variables: {
              input: {
                releaseId: data?.updateContractorRelease.id,
                version: data?.updateContractorRelease.version,
                retroactive: true,
              },
            },
          });
        }
        if (!errors) {
          fetchVendorPrices(true);
          resetPreviousData();
          refetch();
        }
        return !errors;
      } catch (error) {
        setError(error);
        return false;
      }
    }

    return true;
  };

  useEffect(() => {
    if (deliverySlip && release) {
      if (
        release.items.every((item) =>
          receivedQuantities.find(
            (receivedQuantity) => receivedQuantity.releaseItemId === item.id,
          ),
        )
      ) {
        return;
      }
      setReceivedQuantities(
        release?.items.map((item) => {
          const slipItem = deliverySlip?.deliveredReleaseItems.find(
            (drItem) =>
              drItem.releaseItem.id === item.id && Number(drItem.quantity) > 0,
          );
          const hintQuantity = deliverySlip.releaseItemHints.find(
            (h) => h.releaseItem.id === item.id,
          )?.deliverySlipItem?.quantity;
          return {
            releaseItemId: item.id,
            receivedQuantityDecimal:
              slipItem?.quantity ??
              hintQuantity?.toString() ??
              new Decimal(item.quantityDecimal)
                .sub(item?.receivedQuantityDecimal || 0)
                .toString(),
          };
        }),
      );
    }
  }, [deliverySlip, release, receivedQuantities]);

  const updateReceivedItemQuantity = useCallback(
    (id: string, quantityDecimal: string) => {
      setReceivedQuantities((prev) => {
        const index = prev.findIndex((item) => item.releaseItemId === id);
        if (index !== -1) {
          return prev.map((item) =>
            item.releaseItemId === id
              ? { releaseItemId: id, receivedQuantityDecimal: quantityDecimal }
              : item,
          );
        } else {
          return [
            ...prev,
            { releaseItemId: id, receivedQuantityDecimal: quantityDecimal },
          ];
        }
      });
    },
    [],
  );

  const updateReleaseDeliverySlips = useCallback(
    async (deliverySlipUrls: string[]) => {
      try {
        const { errors } = await updateRelease({
          variables: {
            input: {
              releaseId: release?.id ?? "",
              version: release?.version ?? 0,
              deliverySlipUrls,
            },
          },
          awaitRefetchQueries: true,
          refetchQueries: [
            {
              query: RELEASE,
              variables: { id: release?.id },
            },
          ],
        });
        setError(errors);
        return !errors;
      } catch (error) {
        setError(error);
        return false;
      }
    },
    [release?.id, release?.version, setError, updateRelease],
  );

  return (
    <ProviderContext.Provider
      value={{
        syncCreateReleaseFromDeliverySlip,
        syncUpdateReleaseFromDeliverySlip,
        updateReleaseDeliverySlips,
        loading: creatingRelease || saving,
        updating,
        spreadsheetViewColumns: useDeliverySlipReleaseSpreadsheetConfig(),
        setDeliveryPhotos,
        receivedQuantities,
        updateReceivedItemQuantity,
        updateReleaseForm,
        setRequestedTime,
        requestedTime,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useDeliverySlipRelease = (): ProviderContextType =>
  useContext(ProviderContext);
