import { OrderTypeSummaryFieldsFragment, TaxType } from "@/generated/graphql";
import { useMemo } from "react";
import { useOrderTypeOptions } from "../../order-type-picker/hooks/useOrderTypeOptions";
import { getIsNativeSalesTax } from "../utils/salesTaxUtils";

export const useTaxType = (
  orderTypeId: string | undefined | null,
  fallbackOrderTypes: OrderTypeSummaryFieldsFragment[] = [],
  taxType?: TaxType | null | undefined,
) => {
  const { getOrderType } = useOrderTypeOptions();
  const orderType = useMemo(
    () =>
      getOrderType(orderTypeId) ||
      fallbackOrderTypes.find((type) => type.id === orderTypeId),
    [fallbackOrderTypes, getOrderType, orderTypeId],
  );
  const isNativeSalesTax = useMemo(
    () => getIsNativeSalesTax(orderType),
    [orderType],
  );

  const taxTypeKey = useMemo(
    () => (isNativeSalesTax ? "TAX_TYPE" : "SALES_TAX"),
    [isNativeSalesTax],
  );

  const useTaxKey = useMemo(
    () => (taxType === TaxType.Use ? "USE_TAX" : "SALES_TAX"),
    [taxType],
  );

  return {
    isNativeSalesTax,
    taxTypeKey,
    useTaxKey,
  };
};
