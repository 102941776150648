import { useErrorEffect } from "@/common/hooks/useErrorEffect";
import { useOrgPreferredVendorsQuery } from "@/generated/graphql";
import { useCallback, useMemo } from "react";

export const useVendors = () => {
  const { data, loading, error } = useOrgPreferredVendorsQuery({
    variables: {
      first: 10000,
    },
  });

  const vendors = useMemo(
    () =>
      data?.orgPreferredVendorsConnection.edges
        .map((e) => e.node)
        .toSorted((vendorA, vendorB) =>
          vendorA.sellerOrgLocation.org.name.localeCompare(
            vendorB.sellerOrgLocation.org.name,
          ),
        ) ?? [],
    [data],
  );

  const findOrderTypeByLocationId = useCallback(
    (locationId: string) => {
      return vendors.find(
        (vendor) => vendor.sellerOrgLocation.id === locationId,
      )?.defaultReleaseType?.id;
    },
    [vendors],
  );

  useErrorEffect(error);

  return {
    vendors,
    loading,
    error: !!error,
    findOrderTypeByLocationId,
    totalCount: data?.orgPreferredVendorsConnection.totalCount ?? 0,
  };
};
