import { If } from "@/common/components/if/If";
import { LinkLike } from "@/common/components/link-like/LinkLike";
import { Price } from "@/common/components/price/Price";
import { VendorLogo } from "@/common/components/vendor-logo/VendorLogo";
import { routes } from "@/config/routes";
import {
  ProjectReportReleasesFieldsFragment,
  ReleaseStatus,
} from "@/generated/graphql";
import { AccessTime, Check } from "@mui/icons-material";
import { FC } from "react";
import { FormattedDate, FormattedMessage } from "react-intl";
import { generatePath } from "react-router-dom";
import tw from "tailwind-styled-components";
import { useSpendingReportTotal } from "../../hooks/useSpendingReportTotal";

const Container = tw.div`grid my-5
${({ $includeLogo }: { $includeLogo?: boolean }) =>
  !$includeLogo ? "grid-cols-[40px_2fr_1fr]" : "grid-cols-[2fr_1fr]"}
`;

const StyledCheck = tw(Check)`w-5 mr-2`;
const StyledClock = tw(AccessTime)`w-5 mr-2`;
const DeliveryNumber = tw.div`font-medium text-sm`;
const ReleaseItemName = tw.div`grid grid-flow-col justify-start items-center flex-col ml-2`;
const VendorImage = tw.div`flex flex-row items-center`;
const OrderDetailsContainer = tw.div`flex flex-col gap-y-0.5`;
const IconContainer = tw.div`flex flex-row items-center`;
const DeliveryDate = tw.div`flex flex-row items-center font-normal text-xs`;
const OrderLinksContainer = tw.div`flex flex-row items-center gap-x-2`;
const BuyoutNumber = tw.div` text-xs font-normal`;
const EmptyIconView = tw.div`w-5 mr-2`;
const StyledPrice = tw(Price)`text-right text-sm font-medium`;

type Release = ProjectReportReleasesFieldsFragment["elements"][0]["release"];

type ReleaseItemProps = {
  release: Release;
  includeVendorHeader?: boolean;
};

const getIconForReleaseStatus = (releaseStatus: ReleaseStatus) => {
  switch (releaseStatus) {
    case ReleaseStatus.Scheduled:
    case ReleaseStatus.Requested:
      return <StyledClock />;
    case ReleaseStatus.Received:
    case ReleaseStatus.PartiallyReceived:
      return <StyledCheck />;
    default:
      return <EmptyIconView />;
  }
};

const getTimeForReleaseStatus = (release: Release) => {
  switch (release.status) {
    case ReleaseStatus.Received:
    case ReleaseStatus.PartiallyReceived:
    case ReleaseStatus.Scheduled:
    case ReleaseStatus.Requested:
      return release.time;
    default:
      return null;
  }
};

export const ReportReleaseItem: FC<ReleaseItemProps> = ({
  release,
  includeVendorHeader,
}) => {
  const time = getTimeForReleaseStatus(release);
  const { total } = useSpendingReportTotal({
    releaseId: release.id,
  });

  return (
    <Container $includeLogo={includeVendorHeader}>
      <If isTrue={!includeVendorHeader}>
        <VendorImage>
          <VendorLogo
            logoImageUrl={release.sellerOrgLocation?.org.photoUrl}
            orgName={release.sellerOrgLocation?.org.name as string}
            address={release.sellerOrgLocation?.address}
          />
        </VendorImage>
      </If>
      <ReleaseItemName>
        <IconContainer>{getIconForReleaseStatus(release.status)}</IconContainer>
        <OrderDetailsContainer>
          <OrderLinksContainer>
            <LinkLike
              to={generatePath(routes.delivery, {
                deliveryId: release.id,
              })}
            >
              <FormattedMessage
                id="DELIVERY_WITH_NUMBER"
                values={{ number: release.sequenceNumber }}
                tagName={DeliveryNumber}
              />
              {release.buyout ? ", " : ""}
            </LinkLike>
            {release.buyout ? (
              <LinkLike
                to={generatePath(routes.buyout, {
                  id: release?.buyout.id,
                })}
              >
                <FormattedMessage
                  id="BUYOUT"
                  values={{
                    number: release.buyout.clientIdentifier,
                  }}
                  tagName={BuyoutNumber}
                />
              </LinkLike>
            ) : null}
          </OrderLinksContainer>
          <If isTrue={time}>
            <DeliveryDate>
              <FormattedDate value={time ?? undefined} />
            </DeliveryDate>
          </If>
        </OrderDetailsContainer>
      </ReleaseItemName>
      <StyledPrice price={total} />
    </Container>
  );
};
