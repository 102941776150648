import { FloatingFooter } from "@/common/components/footer/FloatingFooter";
import { GridViewToggle } from "@/common/components/grid-view-toggle/GridViewToggle";
import { If } from "@/common/components/if/If";
import { useFormatNumberToCurrency } from "@/common/components/value-currency/hooks/useFormatNumberToCurrency";
import { Base, SmdFixedContainer } from "@/common/layout/ResponsiveClasses";
import { TableViewState } from "@/common/stores/useTableViewStore";
import {
  BUDGET_VIEW_TYPE,
  useSpendingReport,
} from "@/contractor/pages/home/project/providers/ProjectSpendingReportProvider";
import { useSyncEstimatedItems } from "@/contractor/pages/home/project/providers/SyncEstimatedItemsProvider";
import CoPresentOutlinedIcon from "@mui/icons-material/CoPresentOutlined";
import Inventory2Icon from "@mui/icons-material/Inventory2";
import { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { CurrencyDisplay } from "../../spending-report-list/components/CurrencyDisplay";
import { ReportItemDisplay } from "../../spending-report-list/components/ReportItemDisplay";
import { ValueWithTax } from "../../spending-report-list/components/cost-codes-totals/CostCodeTotal.Styles";

const FooterContent = tw.div`
  justify-between flex md:grid grid-flow-col items-center lg:flex px-2 gap-1 md:-mx-14 lg:mx-0
`;
const SwitchContainer = tw(Base)`
  basis-full lg:basis-2/12 lg:w-2/12
  2xl:basis-2/12 2xl:w-2/12 flex-1
`;
const AmountContainer = tw.div`text-center w-full`;
const ValueWithTaxStyled = tw(ValueWithTax)`text-black`;
const Container = tw(SmdFixedContainer)`border-l border-gray-600 border-dashed`;

const toggleOptions = [
  {
    label: <FormattedMessage id="NOT_ORG_MATERIALS" />,
    icon: <Inventory2Icon className="text-lg" />,
    viewState: TableViewState.materials,
  },
  {
    label: <FormattedMessage id="VENDORS" />,
    icon: <CoPresentOutlinedIcon className="text-lg" />,
    viewState: TableViewState.vendors,
  },
];

export const SpendingReportFooter = () => {
  const { syncEstimatedItems, saving } = useSyncEstimatedItems();
  const { zonesReport, materialsReport, vendorsReport, viewType } =
    useSpendingReport();
  const { formatCurrency } = useFormatNumberToCurrency();

  const currentReport = useMemo(() => {
    switch (viewType) {
      case BUDGET_VIEW_TYPE.ZONES:
        return zonesReport;
      case BUDGET_VIEW_TYPE.MATERIALS:
        return materialsReport;
      case BUDGET_VIEW_TYPE.VENDORS:
        return vendorsReport;
    }
  }, [zonesReport, materialsReport, vendorsReport, viewType]);

  const values = useMemo(() => {
    switch (viewType) {
      case BUDGET_VIEW_TYPE.ZONES:
        return {
          items: [
            undefined,
            undefined,
            zonesReport?.orderedNet,
            zonesReport?.paid,
            zonesReport?.invoiced,
            zonesReport?.overUnder,
          ],
          additionalItems: [
            undefined,
            zonesReport?.ordered,
            zonesReport?.paidTotal,
            zonesReport?.invoicedTotal,
            undefined,
          ],
        };
      case BUDGET_VIEW_TYPE.MATERIALS:
        return {
          items: [
            undefined,
            materialsReport?.orderedNet,
            materialsReport?.received,
            materialsReport?.invoiced,
            materialsReport?.paid,
            materialsReport?.overUnder,
          ],
          additionalItems: [
            undefined,
            materialsReport?.ordered,
            materialsReport?.receivedTotal,
            materialsReport?.invoicedTotal,
            materialsReport?.paidTotal,
            undefined,
          ],
        };
      case BUDGET_VIEW_TYPE.VENDORS:
        return {
          items: [
            undefined,
            vendorsReport?.orderedNet,
            vendorsReport?.received,
            vendorsReport?.invoiced,
            vendorsReport?.paid,
            vendorsReport?.overUnder,
          ],
          additionalItems: [
            undefined,
            vendorsReport?.ordered,
            vendorsReport?.receivedTotal,
            vendorsReport?.invoicedTotal,
            vendorsReport?.paidTotal,
            undefined,
          ],
        };
    }
  }, [zonesReport, materialsReport, vendorsReport, viewType]);

  return (
    <FloatingFooter>
      <FooterContent>
        <SwitchContainer>
          <GridViewToggle
            saveState={syncEstimatedItems}
            saving={saving}
            options={toggleOptions}
          />
        </SwitchContainer>
        <SmdFixedContainer />
        <SmdFixedContainer>
          <AmountContainer>
            <ReportItemDisplay
              item={currentReport}
              overall
              className="text-sm"
            />
          </AmountContainer>
        </SmdFixedContainer>
        {values.items?.map((value, index) => (
          <Container key={index}>
            <AmountContainer>
              <CurrencyDisplay
                value={value}
                className="text-sm"
                showColor={index === values.items.length - 1}
                rightAlign={index === values.items.length - 1}
              />
              <If isTrue={values.additionalItems[index]}>
                <FormattedMessage
                  id="VALUE_WITH_TAX_AND_FEES"
                  tagName={ValueWithTaxStyled}
                  values={{
                    value: formatCurrency(values.additionalItems[index]),
                  }}
                />
              </If>
            </AmountContainer>
          </Container>
        ))}
      </FooterContent>
    </FloatingFooter>
  );
};
