import { NumericalInput } from "@/common/components/numerical-input/NumericalInput";
import { useUser } from "@/common/providers/UserProvider";
import {
  BuyoutFieldsFragment,
  DistributorBuyoutFieldsFragment,
  UpdateContractorBuyoutInput,
  UpdateVendorBuyoutInput,
} from "@/generated/graphql";
import { InfoOutlined } from "@mui/icons-material";
import Decimal from "decimal.js";
import { FC, useCallback, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import {
  AdditionalChargesContainer,
  AdditionalChargesItemContainer,
  ItemContainer,
  TotalItemContainer,
  TotalItemOuter,
} from "../additional-charges/AdditionalCharges.styles";
import { If } from "../if/If";
import { LinkLike } from "../link-like/LinkLike";
import {
  OrgRolesWrapper,
  Permission,
} from "../org-roles-wrapper/OrgRolesWrapper";
import { PaymentTerms } from "../payment-terms/PaymentTerms";
import { Price } from "../price/Price";
import { AmountPercentageSwitch } from "../sales-tax-input/components/use-sales-tax/toggle/AmountPercentageSwitch";
import { Tooltip } from "../tooltip/Tooltip";

const StyledNumericalInput = tw(NumericalInput)`w-full`;
const Label = tw.div`flex gap-3 items-center justify-end`;
const StyledItemContainer = tw(ItemContainer)`mr-0`;
const NoPriceContainer = tw.div`flex justify-end items-center`;
const AdditionalChargesContainerStyled = tw(AdditionalChargesContainer)`my-4`;
const TotalItem = tw.div`-mr-1`;
const Sub = tw.span`font-normal`;
const Item = tw.div`font-medium text-sm`;
const LinkLikeStyled = tw(LinkLike)`pl-1`;

const SubTotalItemContainer = tw(
  AdditionalChargesItemContainer,
)`text-base pr-10`;

enum SalesTaxType {
  Percent = "percent",
  Amount = "amount",
}

type Props = {
  buyout?:
    | BuyoutFieldsFragment
    | DistributorBuyoutFieldsFragment
    | null
    | undefined;
  updateBuyout?: (
    input: UpdateContractorBuyoutInput | UpdateVendorBuyoutInput,
  ) => Promise<boolean>;
  totalDivider?: JSX.Element;
  total: string | null;
  subtotal?: string | null;
  includePaymentTerms?: boolean;
  readonlySalesTax?: boolean;
  customTaxAmount?: string | null;
  customTaxRate?: string | null;
  onTypeChange?: (type: SalesTaxType) => void;
  onTaxRateChange?: (value: string) => void;
  readonly?: boolean;
};

export const BuyoutAdditionalChargesAndTaxes: FC<Props> = ({
  buyout,
  updateBuyout,
  totalDivider,
  total,
  subtotal,
  includePaymentTerms,
  readonlySalesTax,
  customTaxAmount,
  customTaxRate,
  onTypeChange,
  onTaxRateChange,
  readonly = false,
}) => {
  const { isContractor } = useUser();

  const [salesTax, setSalesTax] = useState(
    buyout?.taxRate ? Number(new Decimal(buyout?.taxRate ?? 0).mul(100)) : null,
  );
  const [salesAmount, setSalesAmount] = useState(
    buyout?.customTaxAmount || customTaxAmount || buyout?.taxAmount
      ? Number(buyout?.customTaxAmount || customTaxAmount || buyout?.taxAmount)
      : 0,
  );
  const [type, setType] = useState<SalesTaxType>(
    buyout?.customTaxAmount || customTaxAmount || readonlySalesTax
      ? SalesTaxType.Amount
      : SalesTaxType.Percent,
  );

  useEffect(() => {
    if (buyout) {
      setSalesTax(
        buyout.taxRate
          ? Number(new Decimal(buyout.taxRate).mul(100))
          : salesTax,
      );
      setSalesAmount(
        buyout.customTaxAmount || customTaxAmount || buyout.taxAmount
          ? Number(
              buyout.customTaxAmount || customTaxAmount || buyout.taxAmount,
            )
          : 0,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buyout, customTaxAmount]);

  useEffect(() => {
    if (customTaxRate !== undefined) {
      setSalesTax(Number(customTaxRate) * 100);
    }
    if (!buyout?.customTaxAmount && !customTaxAmount && customTaxRate) {
      setType(SalesTaxType.Percent);
      onTypeChange?.(SalesTaxType.Percent);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customTaxRate]);

  useEffect(() => {
    if (customTaxAmount !== undefined) {
      setSalesAmount(Number(customTaxAmount));
    }
  }, [customTaxAmount]);

  const changeType = useCallback(
    async (type: SalesTaxType) => {
      if (readonly || readonlySalesTax) {
        return;
      }
      if (type) {
        setType(type);
        onTypeChange?.(type);
        if (type === SalesTaxType.Amount) {
          await updateBuyout?.({
            buyoutId: buyout?.id || "",
            version: buyout?.version || 0,
            customTaxAmount: String(salesAmount),
            clearCustomTaxAmount: false,
          });
        } else {
          await updateBuyout?.({
            buyoutId: buyout?.id || "",
            version: buyout?.version || 0,
            taxRate: salesTax
              ? new Decimal(salesTax).dividedBy(100).toString()
              : undefined,
            clearCustomTaxAmount: true,
            customTaxAmount: null,
          });
        }
      }
    },
    [
      buyout?.id,
      buyout?.version,
      onTypeChange,
      readonly,
      readonlySalesTax,
      salesAmount,
      salesTax,
      updateBuyout,
    ],
  );

  return (
    <OrgRolesWrapper
      permissions={isContractor ? [Permission.canViewPrices] : []}
    >
      <AdditionalChargesContainerStyled>
        <If isTrue={subtotal}>
          <SubTotalItemContainer>
            <FormattedMessage id="SUBTOTAL" tagName={Item} />
            <Price
              price={subtotal}
              className="text-sm font-normal"
              zeroValuePlaceholder={
                <NoPriceContainer>
                  --{" "}
                  <Tooltip
                    id="subtotal-price"
                    element={
                      <LinkLikeStyled onClick={() => null} forwardEvent={false}>
                        <InfoOutlined />
                      </LinkLikeStyled>
                    }
                  >
                    <FormattedMessage id="NO_SUBTOTAL_TOOLTIP" />
                  </Tooltip>
                </NoPriceContainer>
              }
            />
          </SubTotalItemContainer>
        </If>
        <PaymentTerms
          item={buyout}
          includePaymentTerms={includePaymentTerms}
          readonly={readonly}
          onChange={(paymentTerm) => {
            updateBuyout?.({
              buyoutId: buyout?.id || "",
              version: buyout?.version || 0,
              paymentTermDays: Number(paymentTerm),
            });
          }}
        />
        <StyledItemContainer>
          <Label>
            <FormattedMessage id="SALES_TAX" />
            <AmountPercentageSwitch
              type={type}
              changeType={changeType}
              readonly={readonly || readonlySalesTax}
            />
          </Label>
          {type === SalesTaxType.Percent ? (
            <StyledNumericalInput
              value={salesTax}
              onChange={(e) => setSalesTax(Number(e.target.value))}
              onBlur={() => {
                if (readonly || readonlySalesTax) {
                  return;
                }
                onTaxRateChange?.(
                  new Decimal(salesTax || 0).dividedBy(100).toString(),
                );
                updateBuyout?.({
                  buyoutId: buyout?.id || "",
                  version: buyout?.version || 0,
                  taxRate: salesTax
                    ? new Decimal(salesTax).dividedBy(100).toString()
                    : "0",
                  clearCustomTaxAmount: true,
                  customTaxAmount: null,
                });
              }}
              suffix="%"
              className="w-full"
              xs
              inputProps={{
                className: "text-right bg-white",
              }}
              staticText={readonly || readonlySalesTax}
            />
          ) : (
            <StyledNumericalInput
              value={salesAmount}
              onChange={(e) => setSalesAmount(Number(e.target.value))}
              onBlur={() => {
                if (readonly || readonlySalesTax) {
                  return;
                }
                updateBuyout?.({
                  buyoutId: buyout?.id || "",
                  version: buyout?.version || 0,
                  customTaxAmount: salesAmount?.toString() || "0",
                  clearCustomTaxAmount: false,
                });
              }}
              includeCurrency
              className="w-full"
              xs
              inputProps={{
                className: "text-right bg-white",
              }}
              staticText={readonly || readonlySalesTax}
            />
          )}
        </StyledItemContainer>
        {totalDivider}
        <TotalItemOuter>
          <TotalItemContainer $highlightTotal={true}>
            <FormattedMessage
              id="TOTAL_BUYOUT"
              values={{ sub: (...chunks) => <Sub>{chunks}</Sub> }}
              tagName={TotalItem}
            />
            <Price
              price={total}
              maximumFractionDigits={2}
              zeroValuePlaceholder={
                <NoPriceContainer>
                  --{" "}
                  <Tooltip
                    id="subtotal-price"
                    element={
                      <LinkLikeStyled forwardEvent={false} onClick={() => null}>
                        <InfoOutlined />
                      </LinkLikeStyled>
                    }
                  >
                    <FormattedMessage id="NO_TOTAL_TOOLTIP" />
                  </Tooltip>
                </NoPriceContainer>
              }
              className="font-bold"
              testId="total-price"
            />
          </TotalItemContainer>
        </TotalItemOuter>
      </AdditionalChargesContainerStyled>
    </OrgRolesWrapper>
  );
};
